import React from 'react'
import { InputContainer, StartedAskingSection } from '../../../styles/GetStarted.styled'

export default function StartedProjectName({ setSelected, selected, setPage }) {
    const handleProject = e => {
        setSelected({ ...selected, projectName: e.target.value })
    }

    const handleKeypress = (e) => {
        if (e.charCode === 13) {
            setPage(prev => prev + 1);
        }
    }

    return (
        <StartedAskingSection>
            <h2 style={{textAlign: "center"}}>What is the name of your {selected.projectPurpose === "company" ? "company project" : "project"}?</h2>
            <InputContainer>
                <input value={selected.projectName} type="text" onChange={handleProject}
                    onKeyPress={handleKeypress} />
            </InputContainer>
        </StartedAskingSection>
    )
}
