import React from 'react'
import { render } from "react-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { StartedAskingSection, StepCard, StepCardWithHover, PricingList, FeatureContainer, SelectedFeature, NextButton } from '../../../styles/GetStarted.styled'
import { GridTwo, GridFour, Flex } from '../../../styles/Grid.styled'
import { FaArrowAltCircleRight, FaCheckCircle, FaInfo, FaInfoCircle, FaMinus, FaRegTimesCircle } from "react-icons/fa";
import Modal from '../../../Shared/Modal';
import { useState } from 'react';
import { Button, ButtonContainer, FloatingContainer, FloatingList } from '../../../styles/Common.styled';
import FeatureModal from '../../../Shared/FeatureModal';

import start from "../../../assets/images/selected.png"
import leaf from "../../../assets/images/leaf.png"
import loader from "../../../assets/images/gif.gif"

export default function StartedFeature({ setSelected, selected, services, showSelectedFeature, setShowSelectedFeature, setPage }) {

    let [showModal, setShowModal] = useState(false);
    let [modalObj, setModalObj] = useState({});
    let [listCount, setListCount] = useState(8);


    const handleDetails = obj => {
        setShowModal(true);
        setModalObj(obj);
    }

    let filteredFeature = services?.filter(service => service?.service?.service_name === selected?.business);

    const handleSee = () => {
        setListCount(prevCount => prevCount + 4);
    }

    const handleProject = obj => {
        let index = selected.features.map(feature => feature.featureName).indexOf(obj?.feature_name);
        if (index > -1) {
            let remaining = selected.features.splice(index, 1);
            setSelected({
                ...selected,
                features: selected.features, productDetails: {
                    price: "",
                    days: ""
                }
            })
            return
        }
        setSelected({
            ...selected,
            features: [...selected.features, { isDevelopmentDone: false, featureName: obj?.feature_name }], productDetails: {
                price: "",
                days: ""
            },
        })
    }

    const handleSelectedFeature = obj => {
        let index = selected.features.map(feature => feature.featureName).indexOf(obj?.featureName);

        if (selected.features.length <= 1) {
            setShowSelectedFeature(false);
        }
        if (index > -1) {
            let remaining = selected.features.splice(index, 1);
            setSelected({
                ...selected,
                features: selected.features, productDetails: {
                    price: "",
                    days: ""
                }
            })
            return
        }
        setSelected({
            ...selected,
            features: [...selected.features, { isDevelopmentDone: false, featureName: obj?.feature_name }], productDetails: {
                price: "",
                days: ""
            },
        })
    }

    const fetchMoreData = () => {
        // a fake async api call like which sends
        // 20 more records in 1.5 secs
        setTimeout(() => {
            if (filteredFeature[0]?.allFeature?.length > listCount) {
                setListCount(prevCount => prevCount + 4);
            }
        }, 1000);
    };

    return (
        <StartedAskingSection>
            <h2 style={{ paddingLeft: "1rem" }}>Select Your Feature</h2>
            <InfiniteScroll
                dataLength={filteredFeature[0]?.allFeature?.slice(0, listCount).length}
                next={fetchMoreData}
                hasMore={true}
                loader={filteredFeature[0]?.allFeature?.length > listCount ? <div style={{ textAlign: "center" }}>
                    <img style={{ textAlign: "center", width: "14rem", height: "12rem", }} src={loader} alt="loader" />
                </div> : ""}
                style={{ width: "100%", height: "550px" }}
                className="infinite_scroll"
            >
                <GridFour gap="3rem" style={{ padding: "1rem" }}>
                    {/* {fetchesFeature.map((i, index) => (
                        <div style={{padding: "20px", border: "1px solid #ddd"}} key={index}>
                            div - #{index}
                        </div>
                    ))} */}
                    {
                        filteredFeature[0]?.allFeature?.slice(0, listCount)?.map((feature, index) => {
                            let selectedIndex = selected.features.findIndex(selectfeature => selectfeature.featureName == feature?.feature?.feature_name);
                            return <StepCard justify="space-between" pad="1rem 2rem 2rem" height="200px" >
                                {
                                    selectedIndex > -1 ? <div className='selected'>
                                        <FaCheckCircle />
                                    </div> : ""
                                }
                                <img src={leaf} className="leaf" />
                                <div className='tags'>
                                    {
                                        feature?.feature?.admin_section && <div>#admin_section</div>
                                    }
                                    {
                                        feature?.feature?.consumer_section && <div>#consumer_section</div>
                                    }
                                    {
                                        feature?.feature?.must_have && <div>#must_have</div>
                                    }
                                </div>
                                <p style={{ fontWeight: "700", textAlign: "center" }}>{feature?.feature?.feature_name}</p>
                                <div className='btn_container'>
                                    <div className='detail' onClick={() => handleDetails(feature?.feature)}>
                                        <FaInfo />
                                    </div>
                                    <button className='btn_select' style={{ backgroundColor: selectedIndex > -1 ? "#ED4C67" : "#1dd1a1" }} onClick={() => handleProject(feature.feature)}>{selectedIndex > -1 ? "Remove" : "Select"} </button>
                                </div>
                            </StepCard>
                        }
                        )
                    }

                    {
                        showModal ? <Modal setShowModal={setShowModal}>
                            {/* <img src={modalObj?.feature_image} alt={modalObj?.feature_name} /> */}
                            <h2 className='heading' style={{marginBottom: "0rem"}}>{modalObj?.feature_name}</h2>
                            <p className='caption'>{modalObj?.feature_description}</p>
                        </Modal> : ""
                    }

                    {
                        showSelectedFeature && selected.features?.length ? <FloatingList>
                            {/* <SelectedFeature> */}
                            <FeatureModal setShowModal={setShowSelectedFeature}>

                                {/* <div className='cross'>
                                    <FaMinus onClick={() => setShowSelectedFeature(false)} />
                                </div> */}
                                <h2 style={{ textAlign: "center" }}>Your Selected Features</h2>
                                <div className='container'>

                                    {
                                        selected.features.map(feature => (
                                            <div className='content'>
                                                <p>{feature?.featureName}</p>
                                                <FaRegTimesCircle onClick={() => handleSelectedFeature(feature)} />
                                            </div>
                                        ))
                                    }
                                </div>

                                <div className='circle_container'>

                                    <div className='circle'>
                                        {selected?.features?.length}
                                    </div>
                                </div>
                                <div style={{ textAlign: "center", marginTop: "2rem" }}>
                                    <button className='btn continue' onClick={() => setPage(prevPage => prevPage + 1)} >Continue</button>
                                </div>

                            </FeatureModal>
                            {/* </SelectedFeature> */}
                        </FloatingList> : ""
                    }

                    {
                        selected?.features?.length ? <FloatingContainer>
                            <ButtonContainer onClick={() => setShowSelectedFeature(true)} width="200px" bg="#FDA109" iconBg="#6494DC" color="#fff" >
                                <div className='icons'>
                                    <img src={start} alt="start" />
                                </div>
                                <p style={{ width: "90%", textAlign: "center" }}>Preview {selected?.features?.length}</p>
                            </ButtonContainer>
                            {/* <button style={{ backgroundColor: "#FDA109" }} onClick={() => setShowSelectedFeature(true)}>Selected Feature {selected?.features?.length}</button> */}
                        </FloatingContainer> : ""
                    }


                </GridFour>
            </InfiniteScroll>
            {/* {
                filteredFeature[0]?.allFeature?.length > listCount && !showModal ? <div style={{ textAlign: "center", marginTop: "1rem" }}>
                    <Button onClick={handleSee}>See More</Button>
                </div> : ""
            } */}
        </StartedAskingSection >

    )
}
