import { useEffect } from "react";
import logo from "./logo.svg";
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "./styles/Global.styled";
import Home from "./Views/Home/Home";
import { Route, Routes } from "react-router-dom";
import NotFound from "./Shared/NotFound";
import SuperAdmin from "./Views/SuperAdmin/SuperAdmin";
import GetStarted from "./Views/GetStarted/GetStarted";
import UserAdmin from "./Views/UserAdmin/UserAdmin";
import Login from "./Views/Login/Login";
import { useAuthContext } from "./hooks/useAuthContext";
import "./index.css";
import SelectedFeatureDetails from "./Views/SuperAdmin/Components/SelectedFeatureDetails";
import SuperAdminLeads from "./Views/SuperAdmin/Components/SuperAdminLeads";
import SuperAdminLeadDetails from "./Views/SuperAdmin/Components/SuperAdminLeadDetails";
import { useCollection } from "./hooks/useCollection";
import AdminLogin from "./Views/Login/AdminLogin";
import AllCustomer from "./Views/SuperAdmin/Components/AllCustomer";
import CustomerPage from "./Views/SuperAdmin/Components/CustomerPage";

import ReactGA from "react-ga";
import AboutUs from "./Views/AboutUs/AboutUs";
import Teams from "./Views/Teams/Teams";
import ContactUs from "./Views/ContactUs/ContactUs";
import SelectedCustomerDetails from "./Views/SuperAdmin/Components/SelectedCustomerDetails";
import Qoute from "./Views/Qoute/Qoute";

import "aos/dist/aos.css";
import CreateOrders from "./Views/SuperAdmin/Components/CreateOrders";
import AdminCreateOrder from "./Views/SuperAdmin/Components/AdminCreateOrder";
import SuperAdminCreatedOrders from "./Views/SuperAdmin/Components/SuperAdminCreatedOrders";
import AdminCreateCashInFlow from "./Views/SuperAdmin/Components/AdminCreateCashInFlow";
import SuperAdminAllCashInFlow from "./Views/SuperAdmin/Components/SuperAdminAllCashInFlow";
import AdminCashOutFlow from "./Views/SuperAdmin/Components/AdminCashOutFlow";
import SuperAdminAllCashOutFlow from "./Views/SuperAdmin/Components/SuperAdminAllCashOutFlow";
import AdminCreateRandD from "./Views/SuperAdmin/Components/AdminCreateRandD";
import SuperAdminAllRandD from "./Views/SuperAdmin/Components/SuperAdminAllRandD";
import ProjectRevenue from "./Views/SuperAdmin/Components/ProjectRevenue";
import FinanceDashboard from "./Views/SuperAdmin/Components/FinanceDashboard/FinanceDashboard";
import AddBlog from "./Views/BlogsDashboard/AddBlog/AddBlog";
import Blogs from "./Views/Blogs/Blogs";
import BlogLearnMore from "./Views/Blogs/Components/BlogLearnMore";
import BlogerLogin from "./Views/Login/BlogerLogin";
import AdminAllBlogs from "./Views/SuperAdmin/Components/AdminAllBlogs";
import FactoryAutomation from "./Views/FactoryAutomation/FactoryAutomation";
const TRACKING_ID = "UA-246852457-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const Theme = {
  color: {
    white: "#fff",
    black: "#1F1D1D",
  },
  mobile: "980px",
  smallMobile: "580px",
};

function App() {
  const { authIsReady, user } = useAuthContext("");

  const { document } = useCollection("user");
  const { document: blogUser } = useCollection("blog_user");

  useEffect(() => {
    if (document.length !== 0)
      localStorage.setItem("credentials", JSON.stringify(document));
  }, [document]);

  useEffect(() => {
    if (blogUser.length !== 0)
      localStorage.setItem("bloger_credentials", JSON.stringify(blogUser));
  }, [blogUser]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <ThemeProvider theme={Theme}>
      <GlobalStyles />
      {authIsReady ? (
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/teams" element={<Teams />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/factory-automation" element={<FactoryAutomation />} />
          <Route path="/login" element={<Login />} />
          <Route path="/case-studies" element={<Blogs />} />
          <Route path="/case-studies/:blogtitle" element={<BlogLearnMore />} />
          <Route path="/admin-login" element={<AdminLogin />} />
          <Route path="/bloger-login" element={<BlogerLogin />} />
          <Route path="/add-blog" element={<AddBlog />} />
          <Route path="/get-started" element={<GetStarted />} />
          <Route path="/qoute" element={<Qoute />} />
          <Route path="/user-admin" element={<UserAdmin />} />
          <Route path="/super-admin" element={<SuperAdmin />} />
          <Route path="/super-admin/leads" element={<SuperAdminLeads />} />
          <Route path="/super-admin/customer" element={<CustomerPage />} />
          <Route path="/super-admin/:id" element={<SuperAdminLeadDetails />} />
          <Route
            path="/super-admin/customer/:id"
            element={<SelectedCustomerDetails />}
          />
          <Route path="/super-admin/orders" element={<CreateOrders />} />
          <Route
            path="/super-admin/create-order"
            element={<AdminCreateOrder />}
          />
          <Route
            path="/super-admin/created-order"
            element={<SuperAdminCreatedOrders />}
          />
          <Route
            path="/super-admin/cash-in-flow"
            element={<AdminCreateCashInFlow />}
          />
          <Route
            path="/super-admin/all-cash-in-flow"
            element={<SuperAdminAllCashInFlow />}
          />
          <Route
            path="/super-admin/cash-out-flow"
            element={<AdminCashOutFlow />}
          />
          <Route
            path="/super-admin/all-cash-out-flow"
            element={<SuperAdminAllCashOutFlow />}
          />
          <Route path="/super-admin/r&d" element={<AdminCreateRandD />} />
          <Route path="/super-admin/all-r&d" element={<SuperAdminAllRandD />} />
          <Route
            path="/super-admin/project-revenue"
            element={<ProjectRevenue />}
          />
          <Route
            path="/super-admin/finance-dashbard"
            element={<FinanceDashboard />}
          />
          <Route path="/super-admin/all-blogs" element={<AdminAllBlogs />} />

          {/* if page not found */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      ) : null}
    </ThemeProvider>
  );
}

export default App;

// Firebase Web client Secret: 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

//Calender Client Id: 672174978445-9qnfjo4rpm77lngcj2mjq8u43fcoved5.apps.googleusercontent.com
//Calender Client Secret: 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

// Tracking_id = UA-246852457-1

// days for feature
// 20-30
// 15-20
// 05-10
