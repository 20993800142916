import React from 'react'
import { NotFoundPage } from '../styles/Common.styled'

export default function NotFound() {
  return (
    <NotFoundPage>
        <h2>Page not Found</h2>
    </NotFoundPage>
  )
}
