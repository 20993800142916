import React from "react";

export default function GetStartedFooter() {
  const year = new Date().getFullYear();
  return (
    <div
      style={{
        textAlign: "center",
        margin: "3.5rem 0 0",
        fontSize: "2rem",
        padding: "2.5rem",
        color: "#636e72",
      }}
    >
      Copyright © {year} ongshak
    </div>
  );
}
// #535353
