import React from 'react'
import { GridFour } from '../../../styles/Grid.styled'
import ridwan from "../../../assets/images/Ridwan.jpg"
import ibrahim from "../../../assets/images/ibrahim.jpg"
import tanim from "../../../assets/images/tanim.jpg"
import shahjalal from "../../../assets/images/shahjalal Rafi.jpg"
import Person from '../../../Shared/Person'
import { Container } from '../../../styles/Common.styled'
import { EmployeeSection } from '../../../styles/About.styled'

const teamObj = [
    {
        id: "01",
        name: "Ridwan Hossain",
        position: "Founder & Chief Executive Officer",
        img: ridwan
    },
    {
        id: "02",
        name: "Md. Ibrahim Khalil Ullah",
        position: "Chief Operating Officer",
        img: ibrahim
    },
    {
        id: "03",
        name: "Shahjalal Rafi",
        position: "Full Stack Developer",
        img: shahjalal
    },
    {
        id: "04",
        name: "SK Tanim",
        position: "Machine Learning Developer",
        img: tanim
    },
]

export default function Employee() {
    return (
        <EmployeeSection>
            <Container>
                <h2 className='heading'>Meet Our Leadership Team</h2>
                <hr style={{ display: 'block', width: '130px', margin: "0 auto 4rem", height: '3px', backgroundColor: 'rgba(253, 161, 9, 1)', border: 0 }} />
                <GridFour>
                    {
                        teamObj?.map(member => (
                            <Person img={member.img} name={member.name} position={member.position} key={member.id} />
                        ))
                    }
                </GridFour>
            </Container>
        </EmployeeSection>
    )
}
