import React from "react";
import { ButtonContainer, Container } from "../styles/Common.styled";
import { FooterSection } from "../styles/Footer.styled";
import { GridFour } from "../styles/Grid.styled";
import phone from "../assets/images/phone.png";
import mail from "../assets/images/mail.png";
import location from "../assets/images/location.png";
import meeting from "../assets/images/meeting.png";
// import facebook from "../assets/images/facebook.png"
import logo from "../assets/images/ongshakLogoWhite1.png";
import facebook from "../assets/images/facebook.svg";
import linkedin from "../assets/images/linkedin.svg";
import twitter from "../assets/images/twitter.svg";
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";

export default function Footer() {
  const year = new Date().getFullYear();
  return (
    <FooterSection>
      <Container style={{ position: "relative" }}>
        {/* <div className='logo_container'>
                    <img src={logo} alt="Ongshak" />
                </div> */}
        <h2>ongshak</h2>
        <GridFour gap="4rem">
          <div className="column  desc">
            From Web Development to Custom Enterprise Softwares and Smart IoT
            devices, we construct our projects with industry-leading
            technologies and provide our clients with the utmost commitment.
          </div>
          <div className="routes column">
            <Link
              to="/teams"
              style={{ color: "currentcolor", textDecoration: "none" }}
            >
              <p>Team</p>
            </Link>
            <NavHashLink
              to="/#services"
              style={{ color: "currentcolor", textDecoration: "none" }}
            >
              <p>Services</p>
            </NavHashLink>
            <Link
              to="/about-us"
              style={{ color: "currentcolor", textDecoration: "none" }}
            >
              <p>About Us</p>
            </Link>
            <Link
              to="/contact-us"
              style={{ color: "currentcolor", textDecoration: "none" }}
            >
              <p>Contact Us</p>
            </Link>
            <Link
              to="/case-studies"
              style={{ color: "currentcolor", textDecoration: "none" }}
            >
              <p>Case Studies</p>
            </Link>
          </div>
          <div className="column ">
            <div className="contact">
              {/* <img src={phone} alt="phone" /> */}
              <FaPhoneAlt />
              <p>+8801784440881</p>
            </div>
            <div className="contact">
              {/* <img src={mail} alt="phone" /> */}
              <FaEnvelope />
              <p>hello@ongshak.com</p>
            </div>
            <div className="contact">
              {/* <img src={location} alt="phone" /> */}
              <FaMapMarkerAlt style={{ width: "2em", height: "2em" }} />
              <p>
                Level- 3A, Plot- 19, Road- 27, Block - J, Banani, Dhaka-1213
              </p>
            </div>
          </div>
          <div className="center">
            <ButtonContainer
              width="250px"
              bg="#FDA109"
              iconBg="#fff"
              color="#fff"
            >
              <div className="icons">
                <img src={meeting} alt="meeting icon" />
              </div>
              <p>BOOK A MEETING</p>
            </ButtonContainer>
          </div>
        </GridFour>
        <div className="bottom">
          <div style={{ textAlign: "center", fontSize: "1.6rem" }}>
            © {year} Ongshak. All rights reserved.
          </div>
          <div className="social">
            <a href="https://www.facebook.com/ongshak/" target="_blank">
              <img src={facebook} alt="facebook" />
            </a>
            <a
              href="https://www.linkedin.com/company/ongshak/mycompany/"
              target="_blank"
            >
              <img src={linkedin} alt="linkedin" />
            </a>
            <a href="https://twitter.com/ongshak" target="_blank">
              <img src={twitter} alt="twitter" />
            </a>
          </div>
        </div>
      </Container>
    </FooterSection>
  );
}
