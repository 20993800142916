import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useCollection } from '../../../../hooks/useCollection';
import { useFirestore } from '../../../../hooks/useFirestore';
import { Container } from '../../../../styles/Common.styled';
import { Table } from '../../../../styles/SuperAdmin.styled';

export default function FinanceAllOrder() {
    const { document: createdOrders } = useCollection("create_order");
    const { document: balances } = useCollection("balances");
    const { addDocument } = useFirestore("balances");
    let [month, setMonth] = useState();
    let [allOrders, setAllOrders] = useState(createdOrders);

    let [startCloseBlance, setStartCloseBlance] = useState(0);
    let [monthlyProfit, setMonthlyProfit] = useState(0);


    useEffect(() => {
        let sorted = createdOrders?.sort(function (a, b) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(b.createdAt?.toDate()) - new Date(a.createdAt?.toDate());
        })
        setAllOrders(sorted)
    }, [createdOrders])

    useEffect(() => {
        let filteredByMonth = createdOrders?.filter(order => order?.order_date?.slice(0, 7) == month);
        setAllOrders(filteredByMonth);

        let filteredBeforeOrder = createdOrders?.filter(order => new Date(order?.order_date?.slice(0, 7)) < new Date(month));
       
        let filteredFromOrder = createdOrders?.filter(order => new Date(order?.order_date?.slice(0, 7)) <= new Date(month));




        let totalCashInAmount = filteredByMonth?.reduce((acc, cur) => acc += ((Number(cur?.cashInFlow?.Advanced?.amount) || 0) + (Number(cur?.cashInFlow?.Second_payment?.amount) || 0) + (Number(cur?.cashInFlow?.Third_payment?.amount) || 0)), 0);

        let totalCashInBeforeAmount = filteredBeforeOrder?.reduce((acc, cur) => acc += ((Number(cur?.cashInFlow?.Advanced?.amount) || 0) + (Number(cur?.cashInFlow?.Second_payment?.amount) || 0) + (Number(cur?.cashInFlow?.Third_payment?.amount) || 0)), 0);

        let totalCashInFromAmount = filteredFromOrder?.reduce((acc, cur) => acc += ((Number(cur?.cashInFlow?.Advanced?.amount) || 0) + (Number(cur?.cashInFlow?.Second_payment?.amount) || 0) + (Number(cur?.cashInFlow?.Third_payment?.amount) || 0)), 0);

        
        


        let totalCashOutAmount = 0
        let CashOutAmount = filteredByMonth?.map((order => {
            let indAmount = order?.cashOutFlow?.reduce((acc, cur) => acc + Number(cur?.amount), 0)
            return totalCashOutAmount = indAmount;
        }))

        let totalCashOutBeforeAmount = 0
        let CashOutBeforeAmount = filteredBeforeOrder?.map((order => {
            let indAmount = order?.cashOutFlow?.reduce((acc, cur) => acc + Number(cur?.amount), 0)
            return totalCashOutBeforeAmount = indAmount;
        }))

        let totalCashOutFromAmount = 0
        let CashOutFromAmount = filteredFromOrder?.map((order => {
            let something = 0;
            let indAmount = order?.cashOutFlow?.reduce((acc, cur) => acc + Number(cur?.amount), 0)
            something += indAmount;
            return totalCashOutFromAmount = indAmount;
        })).reduce((acc, cur) => acc + cur, 0)


        let totalProfit = totalCashInAmount - totalCashOutAmount;
        let totalBeforeProfit = totalCashInBeforeAmount - totalCashOutBeforeAmount;
        let  totalFromProfit = totalCashInFromAmount - CashOutFromAmount;

        // if(totalCashOutFromAmount > 0) {
        //     totalFromProfit = totalCashInFromAmount - totalCashOutFromAmount
        // }else {
        //     totalFromProfit = totalCashInFromAmount + totalCashOutFromAmount
        // }

        


        setMonthlyProfit(totalProfit);

        setStartCloseBlance({
            startBalance: totalBeforeProfit,
            clocingBalance: totalFromProfit
        })

        // let obj = {
        //     month,
        //     totalProfit
        // }

        // let isObjHave = balances?.filter(balance => balance?.month == month)
        // setStartCloseBlance(isObjHave[0])
        // if (!isObjHave.length) {
        //     addDocument(obj)
        // }
    }, [month])

    return (
        <Container>
            <h2 style={{ textAlign: "center", fontSize: "3rem" }}>All Created Order</h2>
            <label>
                <p>Filtered by month:*</p>
                <input type="month" value={month} onChange={e => setMonth(e.target.value)} />
            </label>
            <div>
                <h2>Opening Balance: {startCloseBlance?.startBalance}</h2>
                <h2>clocing Balance: {startCloseBlance?.clocingBalance}</h2>
                <h2>Total Monthly profit: {monthlyProfit}</h2>
            </div>
            <Table grid="repeat(10, 1fr)" style={{ margin: "2rem 0", padding: "4rem 0", fontSize: "2rem", backgroundColor: "#ddd" }}>
                <div className='row' style={{borderBottom: "1px solid #000"}}>
                    <div style={{ fontWeight: "700" }}>Serial No</div>
                    <div style={{ fontWeight: "700" }}>Quotation Number</div>
                    <div style={{ fontWeight: "700" }}>Order Id</div>
                    <div style={{ fontWeight: "700" }}>Order Date</div>
                    <div style={{ fontWeight: "700" }}>Project Name</div>
                    <div style={{ fontWeight: "700" }}>Project Type</div>
                    <div style={{ fontWeight: "700" }}>Company Name</div>
                    <div style={{ fontWeight: "700" }}>Development Timeline</div>
                    <div style={{ fontWeight: "700" }}>Start Date</div>
                    <div style={{ fontWeight: "700" }}>End Date</div>
                </div>
                {
                    allOrders?.map(order => (
                        <div className="row">
                            <p >{order?.sl_no}</p>
                            <p >{order?.qoutation_no}</p>
                            <p >{order?.order_id}</p>
                            <p >{order?.order_date}</p>
                            <p >{order?.project_name}</p>
                            <p >{order?.project_type}</p>
                            <p >{order?.company_name}</p>
                            <p >{order?.development_timeline}</p>
                            <p >{order?.start_date}</p>
                            <p >{order?.end_date}</p>
                        </div>
                    ))
                }
            </Table>
        </Container >
    )
}
