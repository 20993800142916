import React, { useState } from 'react'
import { Form, StartedAskingSection, StartedForm } from '../../../styles/GetStarted.styled'
import bd from "../../../assets/images/bd.png"

import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from '../../../firebase.config';
import { useEffect } from 'react';

export default function StartedPhoneVerification({ phoneNumber, setPhoneNumber, setPage, setSelected, selected }) {
    let [expandForm, setExpandForm] = useState(false);
    let [countryCode, setCountryCode] = useState("+880");
    let [number, setNumber] = useState("");
    let [numberWithCode, setNumberWithCode] = useState("");
    let [otp, setOtp] = useState("");
    let [processVerify, setProcessVerify] = useState(null);
    let [minute, setMinute] = useState(0);
    let [second, setSecond] = useState(0);

    const handleNumber = e => {
        setNumber(e.target.value)
    };
    
    useEffect(() => {
        setNumberWithCode(countryCode.concat(number))
    }, [number, countryCode])

    // decrement timmer
    function startTimer(duration) {
        var start = Date.now(),
            diff,
            minutes,
            seconds;
        function timer() {
            // get the number of seconds that have elapsed since 
            // startTimer() was called
            diff = duration - (((Date.now() - start) / 1000) | 0);

            // does the same job as parseInt truncates the float
            minutes = (diff / 60) | 0;
            seconds = (diff % 60) | 0;

            minutes = minutes < 10 ? "0" + minutes : minutes;
            seconds = seconds < 10 ? "0" + seconds : seconds;

            setMinute(minutes);
            setSecond(seconds);

            // display.textContent = minutes + ":" + seconds;

            if (diff <= 0) {
                // add one second so that the count down starts at the full duration
                // example 05:00 not 04:59
                start = Date.now() + 1000;
            }
        };
        // we don't want to wait a full second before the timer starts
        timer();
        setInterval(timer, 1000);
    }


    const generateRecatcha = () => {
        window.recaptchaVerifier = new RecaptchaVerifier('recaptcha', {
            'size': 'invisible',
            'callback': (response) => {
                // reCAPTCHA solved, allow signInWithPhoneNumber .
                // onSignInSubmit();
            }
        }, auth);
    }

    const requestOTP = e => {
        e.preventDefault();
        if (numberWithCode.length > 10) {
            setExpandForm(true);

            var threeMinutes = 60 * 3;
            startTimer(threeMinutes);

            generateRecatcha();
            let appVerifier = window.recaptchaVerifier;
            signInWithPhoneNumber(auth, numberWithCode, appVerifier)
                .then(confirmationResult => {
                    window.confirmationResult = confirmationResult;
                })
                .catch((error) => {
                    // console.log("err", error)
                })
        }

    }

    const verifyOtp = (e) => {
        e.preventDefault();
        setProcessVerify(true)

        if (otp?.length === 6) {
            // verify otp
            let confirmationResult = window.confirmationResult;
            confirmationResult.confirm(otp).then((result) => {
                // User signed in successfully.
                const user = result.user;
                setPhoneNumber(user.phoneNumber)
                setSelected({ ...selected, userDetails: { ...selected.userDetails, phoneNumber: user.phoneNumber } })
                setPage(prev => prev + 1)
                setProcessVerify(false)
                // ...
            }).catch((error) => {
                // User couldn't sign in (bad verification code?)
                // ...
                setProcessVerify(false)
            });

        }

    }

    return (
        <StartedAskingSection>
            <h2 style={{ textAlign: 'center' }}>Your Phone Number {phoneNumber === "" ? "" : "has been verified"}</h2>
            {
                phoneNumber == "" ? <StartedForm onSubmit={otp.length === 6 ? verifyOtp : requestOTP}>
                    {
                        phoneNumber === "" ? <label className='flex'>
                            <div>
                                <img src={bd} alt="bangladesh" />
                            </div>
                            <input value={number} type="tel" onChange={handleNumber} placeholder="1812345678" />
                        </label> : ""
                    }

                    {
                        expandForm && <label>
                            <p style={{marginBottom: "1.5rem"}}>Please verify your phone number within {(second > 0 && minute > 0) ? <span style={{ fontWeight: "800" }}>{minute}:{second} second</span> : ""}. We have sent you an OTP code. </p>
                            <input type="tel" value={otp} onChange={e => setOtp(e.target.value)} />
                        </label>
                    }

                    {
                        !expandForm ? <div style={{ width: "150px", textAlign: "center", margin: "0 auto" }}><input type="submit" className='submit' value="Submit" /></div> : ""
                    }

                    {
                        otp.length === 6 && processVerify !== true ? <div style={{ width: "150px", textAlign: "center", margin: "0 auto" }}><input className='submit' type="submit" value="Verify phone" /></div> : ""
                    }

                    {
                        otp.length === 6 && processVerify === true ? <div style={{ width: "150px", textAlign: "center", margin: "0 auto" }}><input className='submit' value="Processing" /></div> : ""
                    }

                    {
                        phoneNumber === "" ? "" : <p>Verified your phone number</p>
                    }


                </StartedForm> : ""
            }

            <div id="recaptcha"></div>
        </StartedAskingSection>
    )
}
