import React from 'react'
import { InlineWidget } from "react-calendly";
import { CalenderSection } from '../../../styles/UserAdmin.styled';
;

export default function CalenderBook() {
  return (
    <CalenderSection>
      <h2 >Calender</h2>
      <InlineWidget 
      style={{height: "300px !important"}}
        url="https://calendly.com/ongshakbd/20min" />
    </CalenderSection>
  )
}
