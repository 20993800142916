import React from 'react'
import ProtectedRoute from '../../../ProtectedRoute/ProtectedRoute'
import { UserDashboard } from '../../../styles/UserAdmin.styled'
import AdminSidebar from './AdminSidebar'
import AllSelectedFeature from './AllSelectedFeature'

export default function SuperAdminLeads() {
    return (
        <ProtectedRoute>

            <UserDashboard>
                <AdminSidebar />
                <AllSelectedFeature />
            </UserDashboard>
        </ProtectedRoute>
    )
}
