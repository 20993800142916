import React from 'react'
import { GridFour } from '../../../styles/Grid.styled'
import transform1 from "../../../assets/images/transform1.svg"
import transform2 from "../../../assets/images/transform2.svg"
import transform3 from "../../../assets/images/transform3.svg"
import transform4 from "../../../assets/images/transform4.svg"
import { Container } from '../../../styles/Common.styled'
import { TransformSection } from '../../../styles/About.styled'

export default function Transform() {
    return (
        <TransformSection>
            <Container>

                <h2 className='heading' style={{marginBottom: "4rem"}}>Leaders Transforming The Industry</h2>
                <hr style={{ display: 'block', width: '130px', margin: "0 auto 4rem", height: '3px', backgroundColor: 'rgba(253, 161, 9, 1)', border: 0 }} />
                <GridFour>
                    <div className='card'>
                        <img src={transform1} alt="transform 1" />
                        <p>Transparency</p>
                    </div>
                    <div className='card'>
                        <img src={transform2} alt="transform 1" />
                        <p>Accountability</p>
                    </div>
                    <div className='card'>
                        <img src={transform3} alt="transform 1" />
                        <p>Visibility</p>
                    </div>
                    <div className='card'>
                        <img src={transform4} alt="transform 1" />
                        <p>Trust</p>
                    </div>
                </GridFour>
            </Container>
        </TransformSection>
    )
}
