import React from 'react'
import { ProjectDetailSection } from '../../../styles/UserAdmin.styled'
import PaymentDetails from './PaymentDetails'
import ProjectPhase from './ProjectPhase'
import UserGreet from './UserGreet'

export default function ProjectDetails({ customerObject, featureCompletedPercentage }) {
  return (
    <ProjectDetailSection>
      <UserGreet customerObject={customerObject} />
      <PaymentDetails customerObject={customerObject} />
      <ProjectPhase customerObject={customerObject} featureCompletedPercentage={featureCompletedPercentage} />
    </ProjectDetailSection>
  )
}
