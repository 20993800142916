import styled from "styled-components";

export const Navigation = styled.nav`
    /* min-height: 10vh; */
    height: 100px;
    z-index: 1000;
    /* background-color: #fff; */
    padding: 3rem;

    display: flex ;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 0;
    left: 0;

    @media (max-width: ${({ theme }) => theme.smallMobile}) {
        padding: 1rem;
    }
`

export const NavigationContent = styled.div`
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    padding: .5rem 3rem;

    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
        width: 200px;
    }

    @media (max-width: 1410px) {
        padding: .5rem 0;
    }
    @media (max-width: ${({ theme }) => theme.smallMobile}) {
        img {
            width: 150px;
        }
        /* flex-direction: column;
        gap: 2rem; */
    }
    @media (max-width: 320px) {
        img {
            width: 115px;
        }
        /* flex-direction: column;
        gap: 2rem; */
    }
`