import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "@firebase/auth";
import { getFirestore } from "@firebase/firestore";
import { getStorage } from "firebase/storage";
import { Timestamp } from "firebase/firestore"

const firebaseConfig = {
    apiKey: "AIzaSyBHkfAbdoI1g4lK8rHbfL_tMtUTtjY4DPM",
    authDomain: "ongshak-mvp-v1.firebaseapp.com",
    projectId: "ongshak-mvp-v1",
    storageBucket: "ongshak-mvp-v1.appspot.com",
    messagingSenderId: "321453727452",
    appId: "1:321453727452:web:b959d18e10983703fb9e19",
    measurementId: "G-N7LQ56QS4P"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth();
export const timeStamp = Timestamp;
export const analytics = getAnalytics();
export const projectStorage = getStorage();