import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../../../firebase.config';
import { useAuthContext } from "../../../hooks/useAuthContext"
import { useFirestore } from '../../../hooks/useFirestore';
import { Form, InformationContainer, NextButton, PrevButton, SelectedContainer, StartedAskingSection, StartedButtonContainer, StartedForm, StartedTopSection } from '../../../styles/GetStarted.styled'
import { v4 as uuidv4 } from 'uuid';

export default function StartedUserInfo({ selected, setSelected, setPage, page }) {
    const { user } = useAuthContext();

    let [isPending, setIsPending] = useState(false);
    let [error, setError] = useState(null);
    let [confirmPass, setConfirmPass] = useState("");
    const { dispatch } = useAuthContext();
    const { addDocument, response } = useFirestore("Selected Feature")

    const navigate = useNavigate("")

    const handleSubmit = e => {
        addDocument({ ...selected, type: "lead" })
        if (response.error === null) {
            navigate("/user-admin")
        }
    }

    const handleSignup = async (e) => {
        e.preventDefault();

        if(confirmPass != selected.userDetails.password) {
            setError("Your password did not match");
            return
        }

        setIsPending(true);
        setError(null)
        try {
            // Create the user
            await createUserWithEmailAndPassword(auth, selected.userDetails.email, selected.userDetails.password);

            if (!auth) {
                throw new Error("Could not complete signup");
            }

            let displayName = selected.userDetails.name;

            // Update the user profile
            await updateProfile(auth.currentUser, { displayName });

            let randomId = uuidv4();

            // add document to the collection with specific id
            await setDoc(doc(db, "users", auth.currentUser.uid), {
                email: selected.userDetails.email,
                password: selected.userDetails.password,
                displayName: selected.userDetails.name,
                phoneNumber: selected.userDetails.phoneNumber
            })

            // Dispatch login action
            dispatch({ type: "LOGIN", payload: auth.currentUser });


            if (selected.userDetails.name !== "" && selected.userDetails.email !== "" && selected.userDetails.password !== "" && selected.userDetails.phoneNumber !== "") {
                if (selected.userDetails.fetching) {
                    if (selected.businessOthers && selected.business !== "") {
                        handleSubmit();
                    } else {
                        setPage(prev => prev + 1);
                    }

                } else {
                    if ((selected.businessOthers && selected.business !== "")) {
                        handleSubmit();
                    } else {
                        setPage(prev => prev + 1);
                    }
                }
            }


            setError("");
            setIsPending(false);

        } catch (error) {
            if (error?.code?.split('auth/',)[1].toLowerCase() == "email-already-in-use".toLowerCase()) {
                setError("You already have an account with this email");
            } else {
                setError(error?.code?.split('auth/',)[1])
            }
            setIsPending(false);
        }
    }
    return (
        <>
            <StartedAskingSection>
                <StartedForm >
                <h2 style={{marginTop: "3rem", fontWeight: "700", textAlign: "center"}}>Register</h2>

                    <label>
                        <p style={{ margin: "1.5rem 0" }}>Your Full Name</p>
                        <input type="text" value={selected.userDetails.name} onChange={e => setSelected({ ...selected, userDetails: { ...selected.userDetails, name: e.target.value } })} />
                    </label>
                    <label >
                        <p style={{ marginBottom: "1.5rem" }}>Email Address</p>
                        <input type="email" value={selected.userDetails?.email} onChange={e => setSelected({ ...selected, userDetails: { ...selected.userDetails, email: e.target.value } })} />
                    </label>
                    <label>
                        <p style={{ margin: "1.5rem 0" }}>Password</p>
                        <input type="password" value={selected.userDetails.password} onChange={e => setSelected({ ...selected, userDetails: { ...selected.userDetails, password: e.target.value } })} />
                    </label>
                    <label>
                        <p style={{ margin: "1.5rem 0" }}>Confirm Password</p>
                        <input type="password" value={confirmPass} onChange={e => setConfirmPass(e.target.value)} />
                    </label>
                    <label>
                        <p style={{ margin: "1.5rem 0" }}>Your Phone Number</p>
                        <input value={selected.userDetails?.phoneNumber} />
                    </label>
                </StartedForm>
            </StartedAskingSection >

            {/* Bottom section */}
            <StartedTopSection noResponsive="true" pad="2rem 0">
                <StartedButtonContainer style={{marginTop: "3rem"}}>
                    <PrevButton onClick={() => setPage(prevPage => prevPage - 1)}>Prev</PrevButton>
                    <NextButton hover="green" onClick={handleSignup}>{isPending ? "Processing" : "Submit"}</NextButton>
                </StartedButtonContainer>

            </StartedTopSection >
            {
                error ? <p style={{ textAlign: "center", fontSize: "1.6rem", color: "red" }}>{error}</p> : ""
            }
        </>
    )
}
