import styled from "styled-components";

export const FactorySection = styled.section`
  padding: 6rem 2rem;
  /* background-color: rgba(9, 51, 166, .7); */
  background-color: #fff;
  color: #000;

  h1 {
    text-align: center;
    font-size: 4.2rem;
    margin-bottom: 4rem;
  }

  .contact_form {
    max-width: 60%;
    margin: 0 auto;
  }

  form {
    input,
    textarea {
      width: 100%;
      border: none;
      border-bottom: 1px solid #0933a6;
      color: #0933a6;
      padding: 0.5rem;
      margin: 1rem 0 2rem;
      font-size: 1.6rem;
      ::placeholder {
        color: rgba(9, 51, 166, 0.5);
      }
      :focus {
        outline: none;
      }
    }

    textarea {
      height: 300px;
      resize: none;
    }

    .submit_container {
      width: 100%;
      margin: 0 auto;
      text-align: center;
      .submit {
        background-color: #0933a6;
        color: #fff;
        width: 150px;
        padding: 0.5rem 0;
        font-size: 2rem;
        font-weight: 700;
        border-radius: 0.8rem;
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        :hover {
          background-color: #1dd1a1;
        }
      }
    }

    .flex {
      display: flex;
      gap: 3rem;
    }
  }
`;
