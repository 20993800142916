import React, { Component } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw,  ContentState, convertFromHTML  } from "draft-js";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import './RichTextEditor.css'
export default class RichTextEditor extends Component {
    constructor(props) {
        let defaultContent = props.value;
        super(props)
        this.state = {
            editorState: EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    convertFromHTML(defaultContent || "")
                )
            ),
        }
    }

    state = {
        editorState: EditorState.createEmpty(),
    };

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    };

    render() {
        const { editorState } = this.state;
        const blogContent = (draftToHtml(convertToRaw(editorState.getCurrentContent())));
        this.props.setBlogContent(blogContent)

        return (
            <div>
                <Editor
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={this.onEditorStateChange}
                    placeholder='Write your blog'
                />
            </div>
        );
    }
}