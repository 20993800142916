import React from 'react'
import { useCollection } from '../../../hooks/useCollection'
import ProtectedRoute from '../../../ProtectedRoute/ProtectedRoute'
import { Container } from '../../../styles/Common.styled'
import { SuperAdminTable, Table } from '../../../styles/SuperAdmin.styled'
import { UserDashboard } from '../../../styles/UserAdmin.styled'
import AdminSidebar from './AdminSidebar'

export default function SuperAdminCreatedOrders() {
    const { document: createdOrders } = useCollection("create_order");

    let sorted = createdOrders?.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.createdAt?.toDate()) - new Date(a.createdAt?.toDate());
    });


    return (
        <ProtectedRoute>
            <UserDashboard>
                <AdminSidebar />
                <Container>
                    <h2 style={{ textAlign: "center", fontSize: "3rem" }}>All Created Order</h2>
                    <Table grid="repeat(10, 1fr)" style={{ margin: "2rem 0", padding: "4rem 0", fontSize: "2rem", backgroundColor: "#ddd" }}>
                        <div className='row'>
                            <div style={{ fontWeight: "700" }}>S/N</div>
                            <div style={{ fontWeight: "700" }}>Q/N</div>
                            <div style={{ fontWeight: "700" }}>O/I</div>
                            <div style={{ fontWeight: "700" }}>O D</div>
                            <div style={{ fontWeight: "700" }}>P N</div>
                            <div style={{ fontWeight: "700" }}>P T</div>
                            <div style={{ fontWeight: "700" }}>C N</div>
                            <div style={{ fontWeight: "700" }}>D T</div>
                            <div style={{ fontWeight: "700" }}>S D</div>
                            <div style={{ fontWeight: "700" }}>E D</div>
                        </div>
                        {
                            sorted?.map(order => (
                                <div className="row">
                                    <p >{order?.sl_no}</p>
                                    <p >{order?.qoutation_no}</p>
                                    <p >{order?.order_id}</p>
                                    <p >{order?.order_date}</p>
                                    <p >{order?.project_name}</p>
                                    <p >{order?.project_type}</p>
                                    <p >{order?.company_name}</p>
                                    <p >{order?.development_timeline}</p>
                                    <p >{order?.start_date}</p>
                                    <p >{order?.end_date}</p>
                                </div>
                            ))
                        }
                    </Table>
                </Container >
            </UserDashboard>
        </ProtectedRoute>


    )
}
