import React from 'react'
import { ModalBackdrop, ModalCard } from '../styles/Common.styled'


export default function Modal({ children , bgPic, setShowModal, width}) {
    return (
        <ModalBackdrop onClick={() => setShowModal(false)}>
            <ModalCard bgPic={bgPic} width={width}>
                {children}
            </ModalCard>
        </ModalBackdrop>
    )
}
