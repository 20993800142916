import React from 'react'
import { useCollection } from '../../../hooks/useCollection'
import ProtectedRoute from '../../../ProtectedRoute/ProtectedRoute'
import { Container } from '../../../styles/Common.styled'
import { SuperAdminTable, Table } from '../../../styles/SuperAdmin.styled'
import { UserDashboard } from '../../../styles/UserAdmin.styled'
import AdminSidebar from './AdminSidebar'

export default function SuperAdminAllCashOutFlow() {
    const { document: create_order } = useCollection("create_order");

    let sorted = create_order?.filter(order => order?.cashOutFlow?.length).sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.createdAt?.toDate()) - new Date(a.createdAt?.toDate());
    });
    return (
        <ProtectedRoute>
            <UserDashboard>
                <AdminSidebar />
                <Container>
                    <h2 style={{ textAlign: "center", fontSize: "3rem" }}>All Cash out flow</h2>
                    <Table grid="repeat(7, minmax(200px, 1fr))" style={{ margin: "2rem 0", padding: "4rem 0", fontSize: "2rem", backgroundColor: "#ddd" }}>
                        <div className='row'>
                            {/* <div style={{ fontWeight: "700" }}>Project Name</div> */}
                            <div style={{ fontWeight: "700" }}>Assigned Project</div>
                            <div style={{ fontWeight: "700" }}>purpose</div>
                            <div style={{ fontWeight: "700" }}>Amount</div>
                            <div style={{ fontWeight: "700" }}>Date of Payment</div>
                            <div style={{ fontWeight: "700" }}>Mode of Payment</div>
                            <div style={{ fontWeight: "700" }}>Transaction Id</div>
                            {/* <div style={{ fontWeight: "700" }}>action</div> */}
                        </div>
                        {
                            sorted?.map(order => (
                                <div style={{margin: "2rem", backgroundColor: "#fff"}}>
                                    <p style={{backgroundColor: "#000", color: "#fff"}}>{order?.project_name}</p>
                                    {
                                        order?.cashOutFlow?.map(feature => (
                                            <div className="row">
                                                <p >{feature?.assigned_project}</p>
                                                <p >{feature?.purpose}</p>
                                                <p >{feature?.amount}</p>
                                                <p >{feature?.date_of_payment}</p>
                                                <p >{feature?.mode_of_payment}</p>
                                                <p >{feature?.transaction_id}</p>
                                            </div>
                                        ))}
                                </div>
                            ))
                        }
                    </Table>
                </Container >
            </UserDashboard>
        </ProtectedRoute>
    )
}
