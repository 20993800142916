import React, { useEffect, useState } from 'react'
import { useCollection } from '../../../hooks/useCollection'
import { useFirestore } from '../../../hooks/useFirestore'
import ProtectedRoute from '../../../ProtectedRoute/ProtectedRoute'
import { ServiceFormContainer } from '../../../styles/SuperAdmin.styled'
import { UserDashboard } from '../../../styles/UserAdmin.styled'
import AdminSidebar from './AdminSidebar'

export default function AdminCreateCashInFlow() {
    let [error, setError] = useState("");
    let [selectedOrder, setSelectedOrder] = useState(null);
    const [cashInFlow, setCashInFlow] = useState({
        order_id: "",
        amount: "",
        payment_type: "",
        date_of_payment: "",
        mode_of_payment: "",
        transaction_id: "",
        deposite_account: "",
        invoice_number: ""
    })
    const { updateDocument, response } = useFirestore("create_order");

    const { document } = useCollection("create_order");


    useEffect(() => {
        let filteredData = document?.filter(data => {
            return data?.order_id === cashInFlow.order_id
        });
        setSelectedOrder(filteredData[0])
    }, [cashInFlow?.order_id])

    const handleCashInFlow = e => {
        e.preventDefault();
        let advancedUpdatedObj = {
            ...selectedOrder,
            "cashInFlow": {
                ...selectedOrder?.cashInFlow,
                "Advanced": {
                    ...cashInFlow
                }
            }
        }
        let secondPaymentUpdatedObj = {
            ...selectedOrder,
            "cashInFlow": {
                ...selectedOrder?.cashInFlow,
                "Second_payment": {
                    ...cashInFlow
                }
            }
        }

        let thirdPaymentUpdatedObj = {
            ...selectedOrder,
            "cashInFlow": {
                ...selectedOrder?.cashInFlow,
                "Third_payment": {
                    ...cashInFlow
                }
            }
        }
        let InvestmentPaymentUpdatedObj = {
            ...selectedOrder,
            "cashInFlow": {
                ...selectedOrder?.cashInFlow,
                "Investment": {
                    ...cashInFlow
                }
            }
        }

        if (cashInFlow?.order_id !== "" && cashInFlow?.amount !== "" && cashInFlow?.payment_type !== "" && cashInFlow?.date_of_payment !== "" && cashInFlow?.mode_of_payment !== "" && cashInFlow?.transaction_id !== "" && cashInFlow?.deposite_account !== "" && cashInFlow?.invoice_number !== "") {
            if (cashInFlow?.payment_type == "Advanced") {
                updateDocument(selectedOrder?.id, advancedUpdatedObj);
            }
            if (cashInFlow?.payment_type == "Second_payment") {
                updateDocument(selectedOrder?.id, secondPaymentUpdatedObj);
            }
            if (cashInFlow?.payment_type == "Third_payment") {
                updateDocument(selectedOrder?.id, thirdPaymentUpdatedObj);
            }
            if (cashInFlow?.payment_type == "Investment") {
                updateDocument(selectedOrder?.id, InvestmentPaymentUpdatedObj);
            }
        } else {
            setError("Please fill all the required field!");
            return
        }

        setCashInFlow({
            order_id: "",
            amount: "",
            payment_type: "",
            date_of_payment: "",
            mode_of_payment: "",
            transaction_id: "",
            deposite_account: "",
            invoice_number: ""
        })
    }


    return (
        <ProtectedRoute>
            <UserDashboard>
                <AdminSidebar />
                <ServiceFormContainer style={{ margin: "2rem auto" }}>
                    <h2 style={{ textAlign: "center", fontSize: "3rem" }}>Create Cash in Flow</h2>

                    <form onSubmit={handleCashInFlow}>
                        <label>
                            <p>Order Id:*</p>
                            <select onChange={e => setCashInFlow({ ...cashInFlow, order_id: e.target.value })}>
                                <option value="">Select an option</option>
                                {
                                    document?.map(project => (
                                        <option value={project?.order_id}>{project?.project_name}</option>
                                    ))
                                }
                            </select>
                        </label>
                        <label>
                            <p>Amount:*</p>
                            <input type="number" placeholder='Amount' value={cashInFlow.amount} onChange={e => setCashInFlow({ ...cashInFlow, amount: e.target.value })} />
                        </label>
                        <label>
                            <p>Payment Type:*</p>
                            <select onChange={e => setCashInFlow({ ...cashInFlow, payment_type: e.target.value })}>
                                <option value="">Select an option</option>
                                <option value="Advanced">Advance</option>
                                <option value="Second_payment">Second Payment</option>
                                <option value="Third_payment">Third Payment</option>
                                <option value="Investment">Investment</option>
                            </select>
                        </label>
                        <label>
                            <p>Date of payment:*</p>
                            <input type="date" placeholder='Date of payment' value={cashInFlow.date_of_payment} onChange={e => setCashInFlow({ ...cashInFlow, date_of_payment: e.target.value })} />
                        </label>

                        <label>
                            <p>Mode of Payment:*</p>
                            <select onChange={e => setCashInFlow({ ...cashInFlow, mode_of_payment: e.target.value })}>
                                <option value="">Select an option</option>
                                <option value="Cash">Cash</option>
                                <option value="Cheque">Cheque</option>
                                <option value="Bank Transfer">Bank Transfer</option>
                                <option value="MFS">MFS</option>
                                <option value="Money Order">Money Order</option>
                            </select>
                        </label>

                        <label>
                            <p>Transaction Id:*</p>
                            <input type="text" placeholder='Transaction Id' value={cashInFlow.transaction_id} onChange={e => setCashInFlow({ ...cashInFlow, transaction_id: e.target.value })} />
                        </label>

                        <label>
                            <p>Deposited Account:*</p>
                            <select onChange={e => setCashInFlow({ ...cashInFlow, deposite_account: e.target.value })}>
                                <option value="">Select an option</option>
                                <option value="IFIC">IFIC</option>
                                <option value="DBBL">DBBL</option>
                                <option value="bKash">bKash</option>
                                <option value="Nagad">Nagad</option>
                            </select>
                        </label>


                        <label>
                            <p>Invoice Number:*</p>
                            <input type="text" placeholder='Invoice Number' value={cashInFlow.invoice_number} onChange={e => setCashInFlow({ ...cashInFlow, invoice_number: e.target.value })} />
                        </label>

                        <input type="submit" value="Submit" />
                    </form>
                    {
                        error && <p style={{ textAlign: "center", color: "red", fontSize: "2rem" }}>{error}</p>
                    }
                    {/* <Button >Submit</Button> */}
                </ServiceFormContainer >
            </UserDashboard >
        </ProtectedRoute>
    )
}
