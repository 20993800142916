// import { useState, useEffect } from "react";
// import { auth, db } from "../firebase.config";
// import { createUserWithEmailAndPassword, updateProfile, sendEmailVerification } from "firebase/auth";
// import { useAuthContext } from "./useAuthContext";
// import { doc, setDoc } from "firebase/firestore";
// import { useNavigate } from "react-router-dom";
// import { useFirestore } from "./useFirestore";


// export const useSignup = () => {
//     const [isCancelled, setIsCancelled] = useState(false);
//     const [error, setError] = useState(null);
//     const [isPending, setIsPending] = useState(false);
//     const { dispatch } = useAuthContext();

//     const signup = async (email, password, displayName, phoneNumber) => {
//         setError(null);
//         setIsPending(true);
//         try {
//             // Create the user
//             await createUserWithEmailAndPassword(auth, email, password);

//             if (!auth) {
//                 throw new Error("Could not complete signup");
//             }

//             // Update the user profile
//             await updateProfile(auth.currentUser, { displayName });

//             // add document to the collection with specific id
//             await setDoc(doc(db, "users", auth.currentUser.uid), {
//                 email,
//                 password,
//                 displayName,
//                 phoneNumber
//             })

//             // Dispatch login action
//             dispatch({ type: "LOGIN", payload: auth.currentUser })

//             if (!isCancelled) {
//                 setError("");
//                 setIsPending(false);
//             }

//         } catch (err) {
//             console.log("iscancelled", isCancelled)
//             if (!isCancelled) {
//                 setError(err.message);
//                 setIsPending(false);
//             }
//         }
//     };

//     useEffect(() => {
//         return () => setIsCancelled(true);
//     }, []);

//     return { signup, error, isPending };
// };



import { useState, useEffect } from "react";
import { auth, db, projectStorage } from "../firebase.config";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { useAuthContext } from "./useAuthContext";
import { doc, setDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

export const useSignup = () => {
    const [isCancelled, setIsCancelled] = useState(false);
    const [error, setError] = useState(null);
    const [isPending, setIsPending] = useState(false);
    const { dispatch } = useAuthContext();

    const signup = async (email, password, displayName, phoneNumber) => {
        setError(null);
        setIsPending(true);
        try {
            // Create the user
            await createUserWithEmailAndPassword(auth, email, password);

            if (!auth) {
                throw new Error("Could not complete signup");
            }

            // Upload the image
            // const uploadPath = `thumbnails/${auth.currentUser.uid}/${thumbnail.name}`;
            // const thumbnailRef = ref(projectStorage, uploadPath);

            // const img = await uploadBytes(thumbnailRef, thumbnail);

            // const photoURL = await getDownloadURL(thumbnailRef);

            // Update the user profile
            await updateProfile(auth.currentUser, { displayName });

            // add document to the collection with specific id
            await setDoc(doc(db, "users", auth.currentUser.uid), {
                email,
                password,
                displayName,
                phoneNumber
            })

            // Dispatch login action
            dispatch({ type: "LOGIN", payload: auth.currentUser });

            if (!isCancelled) {
                setError("");
                setIsPending(false);
            }

        } catch (err) {
            if (!isCancelled) {
                setError(err.message);
                setIsPending(false);
            }
        }
    };

    useEffect(() => {
        return () => setIsCancelled(true);
    }, []);

    return { signup, error, isPending };
};