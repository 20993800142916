import React from 'react'
import { useState } from 'react';
import { FaArrowAltCircleRight, FaCheckCircle } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { useCollection } from '../../../hooks/useCollection';
import { useFirestore } from '../../../hooks/useFirestore';
import { Button } from '../../../styles/Common.styled';
import { DetailsCard, Form } from '../../../styles/GetStarted.styled';

export default function SelectedCustomerDetails() {
    const { id } = useParams();

    const [showInput, setShowInput] = useState("");
    const [showUiInput, setShowUiInput] = useState("");
    const [featureIndex, setFeatureIndex] = useState("");

    const [newObj, setNewObj] = useState({
        link: "",
        done: false
    })
    const [uiObj, setUiObj] = useState({
        link: "",
        done: false
    })
    const [featureIsDone, setFeatureIsDone] = useState(false)

    const { document: customers } = useCollection("customers");
    const { updateDocument } = useFirestore("customers");

    let selectedFeature = customers?.filter(feature => feature?.id === id);

    const handleSubmitFeature = e => {
        e.preventDefault();

        selectedFeature[0].features[featureIndex] = {
            ...selectedFeature[0]?.features[featureIndex],
            isDevelopmentDone: featureIsDone,
        }

        updateDocument(selectedFeature[0]?.id, selectedFeature[0]);
        setFeatureIsDone(false)
        setFeatureIndex("")
    }

    const handleSubmit = e => {
        e.preventDefault();

        let updatedObj = {
            ...selectedFeature[0],

            isTestingDone: showInput === "testing" ? newObj.done : selectedFeature[0]?.isTestingDone,
            developmentLink: showInput === "testing" ? newObj.link : selectedFeature[0].developmentLink,

            isDevelopmentDone: showInput === "development" ? newObj.done : selectedFeature[0]?.isDevelopmentDone,
            developmentLink: showInput === "development" ? newObj.link : selectedFeature[0].developmentLink,

            isDeliveryDone: showInput === "delivery" ? newObj.done : selectedFeature[0]?.isDeliveryDone,
            hostingLink: showInput === "delivery" ? newObj.link : selectedFeature[0].hostingLink,
        }

        updateDocument(selectedFeature[0]?.id, updatedObj);
        setNewObj({
            link: "",
            done: false
        });
        setShowInput("");
    }

    const handleSubmitUi = e => {
        e.preventDefault();

        // isUiDone: {
        //     approved: showUiInput === "uiApproved" ? uiObj.done : selectedFeature[0]?.isUiDone?.approved,
        //     developed: showUiInput === "uiDeveloped" ? uiObj.done : selectedFeature[0]?.isUiDone?.developed,
        //     review: showUiInput === "uiReview" ? uiObj.done : selectedFeature[0]?.isUiDone?.review,
        //     uiLink: uiObj.link || selectedFeature[0]?.isUiDone?.uiLink
        // }

        let updatedUiDevelopedObj = {
            ...selectedFeature[0],
            isUiDone: {
                ...selectedFeature[0]?.isUiDone,
                developed: uiObj.done,
                uiLink: uiObj.link || selectedFeature[0]?.isUiDone?.uiLink
            }
        }

        let updatedUiReviewObj = {
            ...selectedFeature[0],
            isUiDone: {
                ...selectedFeature[0]?.isUiDone,
                review: uiObj.done,
                uiLink: uiObj.link || selectedFeature[0]?.isUiDone?.uiLink
            }
        }

        let updatedUiApprovedObj = {
            ...selectedFeature[0],
            isUiDone: {
                ...selectedFeature[0]?.isUiDone,
                approved: uiObj.done,
                uiLink: uiObj.link || selectedFeature[0]?.isUiDone?.uiLink
            }
        }


        if (showUiInput === "uiDeveloped") {
            updateDocument(selectedFeature[0]?.id, updatedUiDevelopedObj);
        }

        if (showUiInput === "uiReview") {
            updateDocument(selectedFeature[0]?.id, updatedUiReviewObj);
        }

        if (showUiInput === "uiApproved") {
            updateDocument(selectedFeature[0]?.id, updatedUiApprovedObj);
        }
        setUiObj({
            link: "",
            done: false
        });
        setShowUiInput("");

    }

    return (
        <div>
            <div div style={{ maxWidth: "70%", margin: "0 auto" }}>
                <DetailsCard align="stretch">
                    <h2 style={{ textAlign: "center", margin: "2rem 0" }}>#{selectedFeature[0]?.serialNumber}</h2>
                    <div>
                        <p className="bold">Order Date:</p>
                        <p>{selectedFeature[0]?.workOrderDate}</p>
                    </div>
                    <div>
                        <p className="bold">Customer Name:</p>
                        <p>{selectedFeature[0]?.userDetails?.name}</p>
                    </div>
                    <div>
                        <p className="bold">Customer Email:</p>
                        <p>{selectedFeature[0]?.userDetails?.email}</p>
                    </div>
                    <div>
                        <p className="bold">Customer phoneNumber:</p>
                        <p>{selectedFeature[0]?.userDetails?.phoneNumber}</p>
                    </div>
                    <div>
                        <p className="bold">Project Name:</p>
                        <p>{selectedFeature[0]?.projectName}</p>
                    </div>
                    <div>
                        <p className="bold">Project Purpose:</p>
                        <p>{selectedFeature[0]?.projectPurpose}</p>
                    </div>
                    <div>
                        <p className="bold">Project Types:</p>
                        <p>{selectedFeature[0]?.projectTypes?.map(type => type)}</p>
                    </div>
                    <div>
                        <p className="bold">Selected Business:</p>
                        <p>{selectedFeature[0]?.business}</p>
                    </div>
                    <div>
                        <p className="bold">Feature Selected:</p>
                        {/* <p>{selectedFeature[0]?.features?.map(feature => feature?.featureName)}</p> */}
                    </div>
                    <div style={{ width: "100%", gap: "1rem", display: "flex", flexWrap: "wrap" }}>
                        {
                            selectedFeature[0]?.features?.map((feature, index) => (
                                <div key={index} style={{ flexBasis: "32%", dispaly: "flex", gap: "1rem", marginBottom: "1rem", backgroundColor: "#ddd", padding: "1rem" }} >
                                    <FaArrowAltCircleRight />
                                    <p>{feature?.featureName}</p>
                                    {
                                        feature?.isDevelopmentDone ? "" : <FaCheckCircle onClick={() => setFeatureIndex(index)} />
                                    }

                                </div>
                            ))
                        }
                    </div>
                    <div style={{ alignItems: "center" }}>
                        <p className="bold">Ui Phase Developed:</p>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexBasis: "50%" }}>
                            <p>{selectedFeature[0]?.isUiDone?.developed ? "UI developed Done" : "Ui developed not done yet"}</p>
                            <button className='update_btn' onClick={() => setShowUiInput("uiDeveloped")}>Update</button>
                        </div>
                    </div>
                    <div style={{ alignItems: "center" }}>
                        <p className="bold">Ui Phase review:</p>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexBasis: "50%" }}>
                            <p>{selectedFeature[0]?.isUiDone?.review ? "UI review Done" : "Ui review not done yet"}</p>
                            <button className='update_btn' onClick={() => setShowUiInput("uiReview")}>Update</button>
                        </div>
                    </div>
                    <div style={{ alignItems: "center" }}>
                        <p className="bold">Ui Phase approved:</p>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexBasis: "50%" }}>
                            <p>{selectedFeature[0]?.isUiDone?.approved ? "UI approved Done" : "Ui approved not done yet"}</p>
                            <button className='update_btn' onClick={() => setShowUiInput("uiApproved")}>Update</button>
                        </div>

                    </div>
                    <div style={{ alignItems: "center" }}>
                        <p className="bold">Development Phase:</p>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexBasis: "50%" }}>
                            <p>{selectedFeature[0]?.isDevelopmentDone ? "Completed" : "Not completed yet"}</p>
                            <button className='update_btn' onClick={() => setShowInput("development")}>Update</button>
                        </div>
                    </div>
                    <div style={{ alignItems: "center" }}>
                        <p className="bold">Testing Phase:</p>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexBasis: "50%" }}>
                            <p>{selectedFeature[0]?.isTestingDone ? "Completed" : "Not completed yet"}</p>
                            <button className='update_btn' onClick={() => setShowInput("testing")}>Update</button>
                        </div>
                    </div>
                    <div style={{ alignItems: "center" }}>
                        <p className="bold">Deployment Phase:</p>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexBasis: "50%" }}>
                            <p>{selectedFeature[0]?.isDeliveryDone ? "Completed" : "Not completed yet"}</p>
                            <button className='update_btn' onClick={() => setShowInput("delivery")}>Update</button>
                        </div>
                    </div>


                </DetailsCard>

                {
                    showInput !== "" ? <Form onSubmit={handleSubmit}>
                        <h2 style={{ fontSize: "2rem", textAlign: "center" }}>{showInput} update</h2>
                        <label>
                            <p>Paste preview link</p>
                            <input type="text" onChange={e => setNewObj({ ...newObj, link: e.target.value })} />
                        </label>
                        <select onChange={e => setNewObj({ ...newObj, done: e.target.value === "true" ? true : e.target.value === "false" ? false : "" })}>
                            <option value="">Select option</option>
                            <option value="true">Done</option>
                            <option value="false">Not yet</option>
                        </select>
                        <input type="submit" value="Submit" className='submit' />
                    </Form> : ""
                }
                {
                    showUiInput !== "" ? <Form onSubmit={handleSubmitUi}>
                        <h2 style={{ fontSize: "2rem", textAlign: "center" }}>{showUiInput} update</h2>
                        <label>
                            <p>Paste preview link</p>
                            <input type="text" onChange={e => setUiObj({ ...uiObj, link: e.target.value })} />
                        </label>
                        <select onChange={e => setUiObj({ ...uiObj, done: e.target.value === "true" ? true : e.target.value === "false" ? false : "" })}>
                            <option value="">Select option</option>
                            <option value="true">Done</option>
                            <option value="false">Not yet</option>
                        </select>
                        <input type="submit" value="Submit" className='submit' />
                    </Form> : ""
                }
                {
                    featureIndex !== "" ? <Form onSubmit={handleSubmitFeature}>
                        <h2 style={{ fontSize: "2rem", textAlign: "center" }}>{showUiInput} update</h2>
                        <p>Feature Update: {selectedFeature[0]?.features[featureIndex]?.featureName}</p>
                        <select onChange={e => setFeatureIsDone(e.target.value === "true" ? true : false)}>
                            <option value="">Select option</option>
                            <option value="true">Done</option>
                            <option value="false">Not yet</option>
                        </select>
                        <input type="submit" value="Submit" className='submit' />
                    </Form> : ""
                }
            </div>
        </div>
    )
}
