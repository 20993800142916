import React from 'react'
import { SuperAdminContents } from '../../../styles/SuperAdmin.styled'
import AdminServiceForm from './AdminServiceForm'
import AllSelectedFeature from './AllSelectedFeature'

export default function AdminContents() {
  return (
    <SuperAdminContents>
        <AdminServiceForm />
    </SuperAdminContents>
  )
}
