import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useAuthContext } from '../../hooks/useAuthContext';
import { useCollection } from '../../hooks/useCollection';
import { UserDashboard, WaitingCard } from '../../styles/UserAdmin.styled'
import UserContents from './Components/UserContents'
import UserSidebar from './Components/UserSidebar'
import waiting from '../../assets/images/waiting.png'
import { Button } from '../../styles/Common.styled';
import { useLogout } from '../../hooks/useLogout';
import { useNavigate } from 'react-router-dom';

export default function UserAdmin() {
  const { document: customers } = useCollection("customers");
  const { user } = useAuthContext();
  const { LogOut } = useLogout();

  const navigate = useNavigate("");

  let [customerObject, setCustomerObject] = useState([])


  useEffect(() => {
    const filteredcustomerObject = customers.filter(customer => {
      return customer?.userDetails?.email == user?.email
    });
    setCustomerObject(filteredcustomerObject);
  }, [customers, user]);


  const handleLogout = () => {
    LogOut();
    navigate("/")
  }

  return (
    <UserDashboard>
      <UserSidebar />
      <UserContents />
    </UserDashboard>
  )
}
