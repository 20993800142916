import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import Banner from '../../Shared/Banner'
import Footer from '../../Shared/Footer'
import Navbar from '../../Shared/Navbar'
import Testimonial from '../Home/Components/Testimonial/Testimonial'
import Employee from './Components/Employee'
import MissionVission from './Components/MissionVission'
import Transform from './Components/Transform'

export default function AboutUs() {
  const [height, setHeight] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, [])

  const listenToScroll = () => {
    const winScroll = document.body.scrollTop ||
      document.documentElement.scrollTop;
    setHeight(winScroll);
  };
  return (
    <div>
      <Navbar height={height} />
      <Banner>
        <h2>The Most Reliable Company <br /> To Serve You More</h2>
      </Banner>
      <Transform />
      <MissionVission />
      <Employee />
      <Footer />
    </div>
  )
}
