import React from 'react'
import { ClientGrid, ClientSection } from '../../../../styles/Home.styled'
import { Container } from '../../../../styles/Common.styled'
import { GridThree } from '../../../../styles/Grid.styled'
import client1 from "../../../../assets/images/clients/1.png"
import client2 from "../../../../assets/images/clients/2.png"
import client3 from "../../../../assets/images/clients/3.png"
import client4 from "../../../../assets/images/clients/4.png"
import client5 from "../../../../assets/images/clients/5.png"
import client6 from "../../../../assets/images/clients/6.png"

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";


// import required modules
import "swiper/css/navigation";
import { Autoplay, Navigation } from 'swiper';

export default function Clients() {
    return (
        <ClientSection>
            <Container>
                <h2 className='heading'>Clients</h2>
                <hr style={{ display: 'block', width: '10%', margin: "0 auto 6rem", height: '3px', backgroundColor: 'rgba(253, 161, 9, 1)', border: 0 }} />
                <ClientGrid gap="4rem">
                    <div className='center shadow'>
                        <img src={client1} className="healthport" alt="client 1" />
                    </div>
                    <div className='center shadow'>
                        <img src={client2} alt="client 2" />
                    </div>
                    <div className='center shadow'>
                        <img src={client3} alt="client 3" />
                    </div>
                    <div className='center shadow'>
                        <img src={client4} alt="client 4" />
                    </div>
                    <div className='center shadow'>
                        <img src={client5} alt="client 5" />
                    </div>
                    <div className='center shadow'>
                        <img src={client6} alt="client 6" />
                    </div>
                </ClientGrid>
            </Container>
        </ClientSection >
    )
}
