import React from 'react'
import ProtectedRoute from '../../../ProtectedRoute/ProtectedRoute'
import { UserDashboard } from '../../../styles/UserAdmin.styled'
import AdminSidebar from './AdminSidebar'
import AllCustomer from './AllCustomer'

export default function CustomerPage() {
    return (
        <ProtectedRoute>
            <UserDashboard>
                <AdminSidebar />
                <AllCustomer />
            </UserDashboard >
        </ProtectedRoute>
    )
}
