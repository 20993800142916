import React from 'react'
import { Sidebar } from '../../../styles/UserAdmin.styled'
import ongshakLogo from "../../../assets/images/logo.png"
import dashboardIcon from "../../../assets/images/Icons/dashboard.png";
import projectIcon from "../../../assets/images/Icons/project.png";
import logout from "../../../assets/images/logout.png";
import { FaMicrosoft, FaAlignJustify } from "react-icons/fa";
import start from "../../../assets/images/newstart.png"
import { useLogout } from '../../../hooks/useLogout';
import { NavLink, useLocation, useNavigate, Link } from 'react-router-dom';


const activeStyle = {
  backgroundColor: "#51CEDA",
  color: "#fff",
  width: "100%",
  textDecoration: "none",
  borderRadius: ".4rem"

}
const normalStyle = {
  width: "100%",
  textDecoration: "none",
  borderRadius: ".4rem",
  color: "currentColor"
}

export default function UserSidebar() {
  const { LogOut } = useLogout();
  const navigate = useNavigate("");
  const location = useLocation();

  const handleLogout = () => {
    LogOut();
    navigate("/")
  }
  return (
    <Sidebar>
      <Link to="/">
        <div className='logo_container'>
          <img src={ongshakLogo} alt="ongshak" />
        </div>
      </Link>

      <div className='sidebar_content'>
        <NavLink style={location?.pathname == '/user-admin' ? activeStyle : normalStyle
        } to="/user-admin">
          <div className='sidebar_content-data'>
            <img src={dashboardIcon} alt="dashbaord icon" />
            <p>Dashboard</p>
          </div>
        </NavLink>
        <NavLink style={({ isActive }) =>
          isActive ? activeStyle : normalStyle
        } to="/get-started">
          <div className='sidebar_content-data'>
            <img src={start} alt="dashbaord icon" />
            <p>New Journey</p>
          </div>
        </NavLink>
        <div onClick={handleLogout} className='sidebar_content-data'>
          <img src={logout} alt="project icon" />
          <p>Logout</p>
        </div>
        {/* <div className='sidebar_content-data'>
          <FaAlignJustify />
          <p>progress</p>
        </div> */}
      </div>
    </Sidebar>
  )
}
