import React from 'react'
import { GridThree } from '../../../../styles/Grid.styled'
import { ProcessCard, ProcessContainer, ProcessImage, ProcessSection, div, ProcessSteps } from '../../../../styles/Home.styled'
// import processImg from "../../../../assets/images/process.png"
import processImg from "../../../../assets/images/processimg.png"
// import processImg from "../../../../assets/images/processing.svg"
import { Container, Heading } from '../../../../styles/Common.styled'
import { FaShare, FaBrain } from "react-icons/fa";
// import brainstorming from "../../../..brainstorming.svg"
import process1 from "../../../../assets/images/process1.png"
import process2 from "../../../../assets/images/process2.png"
import process3 from "../../../../assets/images/process3.png"
import process4 from "../../../../assets/images/process4.png"
import process5 from "../../../../assets/images/process5.png"
import process6 from "../../../../assets/images/process6.png"

export default function Process() {
    return (
        <ProcessSection>
            <Container>
                <Heading>

                    <h2 className='heading'>Our Work Process</h2>
                    {/* <p>the business cerrent account that is your accounting software. A simple way to run your bussiness.</p> */}
                </Heading>
                <ProcessContainer gap="1rem" >
                    <ProcessSteps>
                        <ProcessCard align="right">
                            <div >
                                <h2>Brainstorming</h2>
                                <p>The business cerrent account that is your accounting </p>
                            </div>
                            <div className='indication'>
                                {/* <FaBrain /> */}

                                <img src={process1} alt="brainstorming" />
                            </div>
                        </ProcessCard>
                        <ProcessCard align="right">
                            <div>
                                <h2>Research</h2>
                                <p>The business cerrent account that is your accounting </p>
                            </div>
                            <div className='indication'>
                                <img src={process2} alt="barinstorming" />
                                {/* <FaShare /> */}
                            </div>
                        </ProcessCard>
                        <ProcessCard align="right">
                            <div>
                                <h2>UI Design</h2>
                                <p>The business cerrent account that is your accounting </p>
                            </div>
                            <div className='indication'>
                                {/* <FaShare /> */}
                                <img src={process3} alt="barinstorming" />
                            </div>
                        </ProcessCard>
                    </ProcessSteps>

                    <ProcessImage>
                        <img src={processImg} alt="process img" />
                    </ProcessImage>


                    <ProcessSteps>
                        <ProcessCard>
                            <div className='indication'>
                                {/* <FaShare /> */}
                                <img src={process4} alt="process img" />
                            </div>
                            <div>
                                <h2>Development</h2>
                                <p>The business cerrent account that is your accounting </p>
                            </div>
                        </ProcessCard>
                        <ProcessCard>
                            <div className='indication'>
                                {/* <FaShare /> */}
                                <img src={process5} alt="barinstorming" />
                            </div>
                            <div>
                                <h2>Testing</h2>
                                <p>The business cerrent account that is your accounting </p>
                            </div>
                        </ProcessCard>
                        <ProcessCard>
                            <div className='indication'>
                                <img src={process6} alt="barinstorming" />
                            </div>
                            <div>
                                <h2>Deployment</h2>
                                <p>The business cerrent account that is your accounting </p>
                            </div>
                        </ProcessCard>
                    </ProcessSteps>
                </ProcessContainer>
            </Container>
        </ProcessSection>
    )
}
