import React from "react";
import { NavLink } from "react-router-dom";
import { useCollection } from "../../../hooks/useCollection";
import { EmployeeSection } from "../../../styles/About.styled";
import { Blog, BlogSection } from "../../../styles/Blog.styled";
import { Container } from "../../../styles/Common.styled";
import { GridFour, GridThree, GridTwo } from "../../../styles/Grid.styled";
import { Dna } from "react-loader-spinner";
import { useState } from "react";
import { useEffect } from "react";

export default function AllBlogs() {
  const [selectedTag, setSelectedtag] = useState("");
  let { document: blogs, isLoading } = useCollection("Blogs", undefined, [
    "createdAt",
    "desc",
  ]);

  useEffect(() => {}, [selectedTag]);

  const uniqueTags = [
    ...new Set([].concat(...blogs?.map((blog) => [...blog.tags]))),
  ];
  return (
    <EmployeeSection>
      <Container>
        <h2 className="heading">Our Case Studies</h2>
        <hr
          style={{
            display: "block",
            width: "130px",
            margin: "0 auto 4rem",
            height: "3px",
            backgroundColor: "rgba(253, 161, 9, 1)",
            border: 0,
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            gap: "2rem",
            padding: "4rem 0",
          }}
        >
          {uniqueTags?.length ? (
            <p
              style={{
                backgroundColor:
                  selectedTag === ""
                    ? "rgb(9, 132, 227)"
                    : "rgba(9, 132, 227,.2)",
                color: selectedTag === "" ? "#fff" : "#000",
                padding: "1.5rem 2rem",
                borderRadius: ".4rem",
                fontSize: "1.4rem",
                cursor: "pointer",
              }}
              onClick={() => setSelectedtag("")}
            >
              All
            </p>
          ) : null}

          {!isLoading && uniqueTags?.length
            ? uniqueTags?.map((tag, id) => (
                <p
                  key={id}
                  style={{
                    backgroundColor:
                      selectedTag === tag
                        ? "rgb(9, 132, 227)"
                        : "rgba(9, 132, 227,.2)",
                    color: selectedTag === tag ? "#fff" : "#000",
                    padding: "1.5rem 2rem",
                    borderRadius: ".4rem",
                    fontSize: "1.4rem",
                    cursor: "pointer",
                  }}
                  onClick={() => setSelectedtag(tag)}
                >
                  {tag}
                </p>
              ))
            : null}
        </div>
        {isLoading ? (
          <div style={{ textAlign: "center" }}>
            <Dna
              visible={true}
              height="120"
              width="120"
              ariaLabel="dna-loading"
              wrapperStyle={{}}
              wrapperClass="dna-wrapper"
            />
          </div>
        ) : null}
        <GridThree gap="7rem">
          {!isLoading && blogs?.length
            ? blogs
                ?.filter((blog) =>
                  selectedTag !== "" ? blog.tags.includes(selectedTag) : blog
                )
                ?.map((blog) => {
                  let blogParams = blog?.title?.split(" ").join("-");
                  return (
                    <NavLink
                      key={blog?.id}
                      to={`/case-studies/${blogParams}`}
                      style={{ textDecoration: "none", cursor: "pointer" }}
                    >
                      <Blog>
                        <img src={blog?.featureImage} alt={blog?.title} />
                        {/* <div className='content'>
                                        <h1>{blog?.title}</h1>
                                        <p>Read Article</p>
                                    </div> */}
                        <div className="blog_title">
                          <h1 className="category">
                            {blog?.tags?.map((tag, index) =>
                              index !== blog?.tags?.length - 1
                                ? ` ${tag},`
                                : ` ${tag}`
                            )}
                          </h1>
                          <a href={blog?.aboutCaseStudy?.url} target="_blank">
                            <h2>{blog?.aboutCaseStudy?.name}</h2>
                          </a>
                        </div>
                      </Blog>
                    </NavLink>
                  );
                })
            : null}
        </GridThree>
      </Container>
    </EmployeeSection>
  );
}
