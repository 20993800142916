import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useFirestore } from '../../hooks/useFirestore'
import Footer from '../../Shared/Footer'
import Navbar from '../../Shared/Navbar'
import { StartedForm } from '../../styles/GetStarted.styled'
import bd from "../../assets/images/bd.png"

import emailjs from '@emailjs/browser';

export default function Qoute() {
    const [height, setHeight] = useState(0);
    let [detail, setDetail] = useState({
        name: "",
        phone: "",
        email: "",
        companyName: "",
        idea: "",
    })

    let [success, setSuccess] = useState("");
    let [error, setError] = useState("");
    let [loading, setLoading] = useState(false);

    const handleSubmit = e => {
        e.preventDefault();
        setLoading(true);
        const templateParams = {
            name: detail.name,
            user_email: detail.email,
            user_phone: detail.phone,
            companyName: detail.companyName,
            idea: detail.idea
        };

        if (detail.name !== "" && detail.phone !== "" && detail.email !== "" && detail.companyName !== "" && detail.idea !== "") {
            emailjs.send('service_mvl6und', 'template_22avzsm', templateParams, 'T6QISHKdsYsxMoeIM')
                .then((response) => {
                    setSuccess("Successfully Submited");
                    setError("");
                    setDetail({
                        name: "",
                        phone: "",
                        email: "",
                        companyName: "",
                        idea: "",
                    })
                    setLoading(false);
                }, (err) => {
                    setError(err);
                    setSuccess("");
                    setLoading(false);
                });
        } else {
            setError("Please, fill all the required field.")
            setSuccess("");
            setLoading(false);
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", listenToScroll);
        return () => window.removeEventListener("scroll", listenToScroll);
    }, [])

    const listenToScroll = () => {
        let heightToHideFrom = 700;
        const winScroll = document.body.scrollTop ||
            document.documentElement.scrollTop;
        setHeight(winScroll);
    };

    return (
        <>
            <Navbar height={height} />
            <div style={{ margin: "4rem 0" }}>

                <StartedForm onSubmit={handleSubmit} >
                    <h2 style={{ marginbottom: "3rem", fontSize: "2.8rem", fontWeight: "700", textAlign: "center" }}>Get a Quote</h2>

                    <label>
                        <p style={{ margin: "1.5rem 0" }}>Your Full Name</p>
                        <input type="text" value={detail.name} onChange={e => setDetail({ ...detail, name: e.target.value })} />
                    </label>
                    <label >
                        <p style={{ marginBottom: "1.5rem" }}>Email Address</p>
                        <input type="email" value={detail.email} onChange={e => setDetail({ ...detail, email: e.target.value })} />
                    </label>
                    <label className='flex'>
                        <div>
                            <img src={bd} alt="bangladesh" />
                        </div>
                        <input type="tel" placeholder='1812345678' value={detail.phone} onChange={e => setDetail({ ...detail, phone: e.target.value })} />
                    </label>
                    <label >
                        <p style={{ marginBottom: "1.5rem" }}>Company Name</p>
                        <input type="text" value={detail.companyName} onChange={e => setDetail({ ...detail, companyName: e.target.value })} />
                    </label>
                    <label>
                        <p style={{ margin: "1.5rem 0" }}>Please tell us what do you need?</p>
                        <input type="text" value={detail.idea} onChange={e => setDetail({ ...detail, idea: e.target.value })} />
                    </label>
                    <div style={{ textAlign: "center" }}>
                        <input className='submit_qoute' type="submit" value={loading ? "Processing" : "Submit"} />
                    </div>
                    {
                        success && <p style={{ textAlign: "center", color: "green", fontSize: "1.8rem", marginTop: "1.5rem" }}>{success}</p>
                    }
                    {
                        error && <p style={{ textAlign: "center", color: "red", fontSize: "1.8rem", marginTop: "1.5rem" }}>{error}</p>
                    }
                </StartedForm>
            </div>
            <Footer />
        </>
    )
}
