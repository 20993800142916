import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { useCollection } from '../../../hooks/useCollection'
import { useFirestore } from '../../../hooks/useFirestore';
import { Button, Container } from '../../../styles/Common.styled';
import { SuperAdminTable } from '../../../styles/SuperAdmin.styled';

export default function AllSelectedFeature() {
    const { document: selectedFeature } = useCollection("Selected Feature");

    let sorted = selectedFeature?.filter(feature => feature?.type !== "customer")?.sort(function(a,b){
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.createdAt?.toDate()) - new Date(a.createdAt?.toDate());
      });

    return (
        <Container>
            <h2 style={{textAlign: "center", fontSize: "3rem"}}>All Leads</h2>
            <SuperAdminTable style={{ margin: "2rem 0", padding: "4rem 0", fontSize: "2rem", backgroundColor: "#ddd" }}>
                <div className='row'>
                    <div style={{ fontWeight: "700" }}>Project name</div>
                    <div style={{ fontWeight: "700" }}>Date</div>
                    <div style={{ fontWeight: "700" }}>Customer Name</div>
                    <div style={{ fontWeight: "700" }}>Customer Type</div>
                    <div style={{ fontWeight: "700" }}>Details</div>
                    {/* <div style={{ fontWeight: "700" }}>action</div> */}
                </div>
                {
                    sorted?.map(feature => (
                        <div className="row">
                            <p >{feature?.projectName}</p>
                            <p >{new Date(feature?.createdAt?.toDate()).toLocaleString()}</p>
                            <p >{feature?.userDetails?.name}</p>
                            <p style={{backgroundColor: (feature?.type && feature.type !== "lead") ? "" : "yellow", display: "inline-block"}} >{feature?.type ? feature?.type : "lead"}</p>
                            <Link to={`/super-admin/${feature?.id}`}>
                                <button>Detail</button>
                            </Link>
                        </div>
                    ))
                }
            </SuperAdminTable>
        </Container >
    )
}
