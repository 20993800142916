import styled, {keyframes} from 'styled-components';

export const anim = (offset) => keyframes`
    100% {
        stroke-dashoffset: ${offset};
    }
`


export const UserDashboard = styled.div`
    display: grid;
    grid-template-columns: 300px 1fr;
    @media (max-width: 800px) {
        grid-template-columns: 1fr;
    }
`

export const WaitingCard = styled.div`
    width: 60%;
    height: 70vh;
    margin: 15vh auto 0;
    background-color: rgba(100, 148, 220, .3);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 2px 2px 15px 2px rgba(0,0,0,0.1);
    border-radius: .8rem;

    img {
        max-width: 500px;
        height: auto;
    }

    p {
        font-size: 2.2rem;
        font-weight: 700;
        margin-bottom: 1rem;
    }
`

export const Sidebar = styled.div`
    position: static;
    top: 0;
    left: 0;
    background-color: #f1f1f1;
    padding: 4rem;
    /* height: 100vh; */
    width: 100%;
    max-height: 100vh;
    position: sticky;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    .logo_container {
        width: 100%;
        display: flex;
        gap: 1rem;
        /* justify-content: center; */
        align-items: center;
        margin-bottom: 2rem;
        /* background-color: red; */
        img {
            width: auto;
            height: 50px;
        }

        p {
            font-size: 2rem;
            font-weight: bold;
        }
    }

    .sidebar_content {
        /* background-color: green; */
        width: 100%;
        height: 100%;
        display: flex;
        gap: 1rem;
        height: 100vh;
        flex-direction: column;
        padding: 1rem;
        &-data {
            display: flex;
            gap: 1rem;
            align-items: center;
            cursor: pointer;
            padding: 0rem;
            padding: 1rem;
            border-radius: .4rem;
            
            img {
                width: 2.5rem;
                height: 2.5rem;
                
            }
            p {
                font-size: 1.8rem;
            }
            :hover {
                background-color: #ddd;
            }
        }
    }   

    @media (max-width: 800px) {
        position: static;
        max-height: 300px;
        height: 100%;
    }
`


export const UserData = styled.div`
    background-color: #F4F5FB;
    padding: 4rem;
    display: grid;
    grid-template-columns: 2fr 1fr;

    @media (max-width: 1320px) {
        grid-template-columns: 1fr;
    }
`

export const ProjectDetailSection = styled.div`
    background-color: #fff;
    border-radius: .8rem;
    padding: 2rem;
    box-shadow: 2px 2px 5px rgba(0,0,0,.1);
`

export const GreetContainer = styled.div`
    background-color: ${({ bg }) => bg || "#fff"};
    border-radius: 1.2rem;
    margin: 2rem 0;
    padding: 4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 4px 2px 6px 4px rgba(0,0,0,.1);

     > div {
        flex: 1;
        
     }
     .img_container {
        text-align: center;
        img {
            height: 300px;
            width: auto;
        }
     }

    h2 {
        font-size: 4.4rem;
        margin-bottom: 1rem;
    }
    p {
        font-size: 1.8rem;
    }

    @media (max-width: 1200px) {
        flex-direction: column;
        text-align: center;
    }
`

export const ProgressSection = styled.section`
    svg {
        width: 5rem;
        height: 5rem;
    }

    h2 {
        font-size: 2rem;
        margin-bottom: 2rem;
    }
    `

export const CalenderSection = styled.section`
    padding: 2rem 0;
    
    h2 {
        font-size: 2rem;
        margin-bottom: 2rem;
    }    
`

export const ProgressCard = styled.div`
    background-color : #fff;
    border-radius: .4rem;
    padding: 2rem;
    font-size: 1.8rem;

    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const RightSideSection = styled.aside`
    padding: 2rem;
`

export const ProjectPhaseSection = styled.section`
    padding: 4rem;
    .heading {
        font-size: 2.2rem;
    }

    .greet {
        font-size: 1.8rem;
        margin-bottom: 2rem;
    }
`

export const PhaseContainer = styled.div`
    padding: 2rem 0;
    display: flex;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
`

export const Circle = styled.div`
    position: relative;
    width: 100px;
    height: 100px;
    margin: 0.5rem;
    border-radius: 50%;
    background: #4fcdda;
    overflow: hidden;
    box-shadow: 6px 6px 10px -1px rgba(0,0,0,.15),
                -6px -6px 10px -1px rgba(221, 221, 221, .6);
    
    &.per {
        background-image: conic-gradient(#689be0 ${({ percentage }) => percentage}%, #4fcdda  0);
    }

    .inner {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 75px;
        height: 75px;
        background: #fff;
        color: #000;
        border-radius: 50%;
        font-size: 1.85em;
        font-weight: 700;
        box-shadow: inset 4px 4px 6px -1px rgba(0,0,0,0.2),
        inset -4px -4px 6px -1px rgba(0,0,0,0.2),
        -0.5px -0.5px 0px rgba(0,0,0,0.2),
        0.5px 0.5px 0px -1px rgba(0,0,0,0.2),
        0px 12px 10px -10px rgba(0,0,0,0.5);
    }
`

export const Phase = styled.div`
    width: 160px;
    height: 160px;
    position: relative;
    
    .outer {
        width: 160px;
        height: 160px;
        border-radius: 50%;
        box-shadow: 6px 6px 10px -1px rgba(0,0,0,.15),
                -6px -6px 10px -1px rgba(221, 221, 221, .6);
        padding: 20px;
    }

    .inner {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: inset 4px 4px 6px -1px rgba(0,0,0,0.1),
        inset -4px -4px 6px -1px rgba(0,0,0,0.1),
        -0.5px -0.5px 0px rgba(0,0,0,0.1),
        0.5px 0.5px 0px -1px rgba(0,0,0,0.1),
        0px 12px 10px -10px rgba(0,0,0,0.1);
    }

    .number {
        font-size: 2rem;
        font-weight: 700;
    }

    circle {
        fill: none;
        stroke: url(#GradientColor);
        stroke-width: 20px;
        stroke-dasharray: 440;
        stroke-dashoffset: 440;
        animation: ${props => anim(props.offset)} 2s linear forwards;
    }

    svg {
        position: absolute;
        top: 0;
        left: 0;
    }
`

// export const PaymentDetails = styled.div`
//     display: flex;
//     justify-content: space-between;
//     align
// `