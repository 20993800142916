import styled from "styled-components";
import bg from "../assets/images/team1.jpeg";

export const ButtonContainer = styled.div`
  background-color: ${({ bg }) => bg || "#fff"};
  width: ${({ width }) => width || "150px"};
  position: relative;
  min-height: 50px;
  border-radius: 0 12px 0 0;
  cursor: pointer;
  /* box-shadow: 2px 2px 10px 3px rgba(0,0,0,.1); */
  box-shadow: 3px 5px 4px rgba(0, 0, 0, 0.3);
  margin-left: 20px;
  /* display: flex; */
  /* justify-content: space-between;
    align-items: center; */

  transition: all 1s;

  :hover {
    background-color: ${({ iconBg }) => iconBg || "#fff"};
    p {
      color: ${({ iconBg }) => (iconBg == "#fff" ? "#000" : "#fff")};
    }
    .icons {
      transition: all 1s;
      background-color: ${({ bg }) => bg || "#000"};
      img,
      svg {
        fill: ${({ bg }) =>
          bg === "#000" ? `#fff !important` : "#000 !important"};
      }
    }
  }

  .icons {
    transition: all 1s;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: ${({ iconBg }) => iconBg || "#000"};

    position: absolute;
    top: 0;
    left: -20px;

    img,
    svg {
      width: 100%;
      height: 100%;
      padding: 1rem;
      object-fit: cover;
      fill: #fff;
    }
  }

  p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.6rem;
    font-weight: 700;
    width: 100%;
    text-align: center;
    color: ${({ color }) => color || "#000"};
    transition: all 0.3s;
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: ${({ rsWidth }) => rsWidth || "200px"};
  }
  @media (max-width: 320px) {
    width: 130px;
  }
`;

export const Button = styled.button`
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  border: none;
  font-size: 2.5rem;
  font-weight: 600;
  padding: 1.2rem 2rem;
  border-radius: 0.8rem;
  cursor: pointer;
  font-family: inherit;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.4);
  z-index: 100;

  &:hover {
    background-color: #000;
    cursor: pointer;
  }
`;

export const Container = styled.div`
  max-width: 140rem;
  margin: 0 auto;
  padding: 3rem;
`;

export const NotFoundPage = styled.section`
  background-color: #ddd;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  h2 {
    font-size: 3.2rem;
  }
`;

export const Heading = styled.div`
  width: 70%;
  margin: 0 auto;
  margin-bottom: ${({ mb }) => mb || "8rem"};
  h2 {
    font-size: 5.2rem;
    text-align: center;
    margin-bottom: 2rem;
  }

  p {
    font-size: 1.8rem;
    text-align: center;
  }

  @media (max-width: ${({ theme }) => theme.smallMobile}) {
    width: 90%;

    h2 {
      font-size: 3.2rem;
    }
  }
`;

export const Card = styled.div`
  background-color: #fff;
  border-radius: 0.4rem;
  padding: 2rem;
  /* font-size: 1.8rem; */
`;

export const FloatingContainer = styled.div`
  position: fixed;
  bottom: 5%;
  right: 5%;
  z-index: 10;

  button {
    padding: 10px 15px;
    border: none;
    background-color: #000;
    color: #fff;
    border-radius: 0.8rem;
    border: none;
    cursor: pointer;
  }
`;

export const FloatingList = styled.div`
  position: fixed;
  bottom: 10%;
  right: 5%;
  z-index: 10;
`;

export const ImgContainer = styled.div`
  display: flex;
  justify-content: ${({ justify }) => justify || "flex-end"};
  align-items: center;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    justify-content: center;
  }
`;

export const BannerSection = styled.div`
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  height: 500px;
  max-height: 500px;
  background-attachment: fixed;

  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  z-index: 1;

  position: relative;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(9, 51, 166, 0.5);
    z-index: 0;
  }

  h2 {
    z-index: 1;
    text-align: center;
    font-size: 6.4rem;

    @media (max-width: ${({ theme }) => theme.mobile}) {
      font-size: 2.8rem;
    }
  }
  @media (max-width: ${({ theme }) => theme.mobile}) {
    height: 200px;
  }
`;

export const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100;

  display: flex;
  justify-content: center;
  align-items: center;

  .overlay {
    position: absolute;
    /* background-color: rgba(0,0,0, .7); */
    width: 100%;
    height: 100%;
  }
`;

export const ModalCard = styled.div`
  width: ${({ width }) => width || "700px"};
  max-width: 60%;
  max-height: 70vh;
  background-color: ${({ bg }) => bg || "#fff"};
  margin: 100px auto;
  color: #000;
  border-radius: 8px;
  box-shadow: 2px 2px 17px 5px rgba(0, 0, 0, 0.3);
  z-index: 100;
  background-image: url(${({ bgPic }) => bgPic});
  background-size: cover;
  overflow: hidden;
  position: relative;
  overflow-y: scroll;
  scrollbar-width: none;
  padding: 3rem;

  ::-webkit-scrollbar {
    width: 0px;
    background-color: #f5f5f5;
  }

  /* display: flex;
   justify-content: center;
    align-items: center; */
  z-index: 2000;

  img {
    height: 350px;
    width: 100%;
    object-fit: contain;
  }

  .heading {
    width: 100%;
    text-align: center;
    font-size: 2.6rem;
    padding: 1rem;
  }

  .caption {
    width: 100%;
    padding: 2rem;
    text-align: justify;
    font-size: 1.8rem;
    height: 100%;
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    max-width: 90%;
    margin: 2rem auto;
  }
`;

export const ModalSelected = styled.div`
  width: ${({ width }) => width || "600px"};
  max-width: 60%;
  max-height: 70vh;
  background-color: ${({ bg }) => bg || "#fff"};
  margin: 100px auto;
  color: #000;
  border-radius: 8px;
  box-shadow: 2px 2px 17px 5px rgba(0, 0, 0, 0.3);
  z-index: 10000;

  overflow: hidden;
  position: relative;
  padding: 3rem;
  overflow-y: scroll;
  scrollbar-width: none;
  z-index: 1000;

  /* ::-webkit-scrollbar-track{
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        background-color: #F5F5F5;
    } */

  ::-webkit-scrollbar {
    width: 0px;
    background-color: #f5f5f5;
  }
  /* 
    ::-webkit-scrollbar-thumb{
        background-color: #000000;
    } */

  .cross {
    position: absolute;
    top: 5px;
    right: 10px;
    svg {
      width: 3rem;
      height: 3rem;
      cursor: pointer;
    }
  }

  .continue {
    background-color: #ff9f43;
    transition: all 0.3s;
    :hover {
      background-color: #1dd1a1;
    }
  }

  .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    z-index: 1000;
    .content {
      z-index: 1000;
      flex-basis: 40%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 1rem;
      padding: 1rem;
      background-color: #fff;
      color: #000;
      border-radius: 0.8rem;
      border: 2px solid #000;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
      p {
        font-size: 1.6rem;
        flex-basis: calc(100% - 3rem);
      }

      svg {
        flex-basis: 3rem;
        width: 2rem;
        height: 2rem;
        cursor: pointer;
        color: #000;
      }
      z-index: 2000;
    }
  }

  .circle_container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .circle {
    font-size: 1.6rem;
    width: 3rem;
    height: 3rem;
    padding: 2rem;
    border: 2px solid #000;
    border-radius: 50%;
    text-align: center;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    max-width: 90%;
    margin: 2rem auto;
    padding: 2rem;
  }
`;

export const PersonCard = styled.div`
  background-color: rgba(9, 51, 166, 0.2);
  padding: 4rem 2rem;
  border-radius: 0.8rem;
  margin-top: 5rem;

  .details_container {
    height: 80px;
    position: relative;
    
  }

  .details_containers {
    text-align: center;
    display: none;

    h3 {
      margin-top: 2rem;
      font-size: 2rem;
      color: #0933a6;
      text-align: center;
    }

    p {
      text-align: center;
      font-style: italic;
      color: #4d4d4d;
      font-size: 2rem;
      margin-bottom: 2rem;
    }
  }

  .social {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin-top: 1rem;
    display: flex;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: 0.3s all ease-in-out;

    img,
    svg {
      width: 30px;
      height: 30px;
      fill: blue;
    }
  }
  .socials {
    display: flex;
    gap: 1rem;
    justify-content: center;
    img,
    svg {
      width: 30px;
      height: 30px;
      fill: blue;
    }
  }

  .img_container {
    text-align: center;
    img {
      margin-top: -50%;
      text-align: center;
      width: 10rem;
      height: 10rem;
      border-radius: 50%;
    }
  }
  .detail {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* height: 70px; */
    transition: 0.3s all ease-in-out;
    h3 {
      margin-top: 2rem;
      font-size: 2rem;
      color: #0933a6;
      text-align: center;
    }

    p {
      text-align: center;
      font-style: italic;
      color: #4d4d4d;
      font-size: 2rem;
    }
  }

  :hover {
    .detail {
      opacity: 0;
      height: 0px;
    }
    .social {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 1;
    }
    img {
      border-radius: 50%;
    }
  }

  @media (max-width: ${({ theme }) => theme.smallMobile}) {
    .details_containers {
      display: block;
    }
    .details_container {
      display: none;
    }
  }
`;
