import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useCollection } from "../../hooks/useCollection";
import {
  GetStartedContainer,
  StartedContainer,
} from "../../styles/GetStarted.styled";
import GetStartedFooter from "./Components/GetStartedFooter";
import PreviewPage from "./Components/PreviewPage";
import StartedBottom from "./Components/StartedBottom";
import StartedBuild from "./Components/StartedBuild";
import StartedBusiness from "./Components/StartedBusiness";
import StartedFeature from "./Components/StartedFeature";
import StartedFeaturePricingList from "./Components/StartedFeaturePricingList";
import StartedForWho from "./Components/StartedForWho";
import StartedOthersBusiness from "./Components/StartedOthersBusiness";
import StartedPhoneVerification from "./Components/StartedPhoneVerification";
import StartedPreview from "./Components/StartedPreview";
import StartedProgress from "./Components/StartedProgress";
import StartedProjectName from "./Components/StartedProjectName";
import StartedTop from "./Components/StartedTop";
import StartedUserInfo from "./Components/StartedUserInfo";

export default function GetStarted() {
  let [page, setPage] = useState(1);
  const [selected, setSelected] = useState({
    projectTypes: [],
    projectPurpose: "",
    projectName: "",
    business: "",
    businessOthers: false,
    features: [],
    productDetails: {
      price: "",
      days: "",
    },
    userDetails: {
      name: "",
      email: "",
      password: "",
      phoneNumber: "",
      fetching: false,
    },
  });
  let [showSelectedFeature, setShowSelectedFeature] = useState(false);

  const { document: allUsers } = useCollection("users");
  const { user } = useAuthContext();

  let [phoneNumber, setPhoneNumber] = useState("");

  useEffect(() => {
    const actualUser = allUsers?.filter(
      (indUser) => indUser?.email === user?.email
    );
    if (actualUser?.length) {
      setPhoneNumber(actualUser[0]?.phoneNumber);
      setSelected({
        ...selected,
        userDetails: {
          ...selected.userDetails,
          name: actualUser[0]?.displayName,
          email: actualUser[0]?.email,
          password: actualUser[0]?.password,
          phoneNumber: actualUser[0]?.phoneNumber,
          fetching: true,
        },
      });
    }
  }, [allUsers]);

  const { document } = useCollection("Services");

  const percentage = 9 > page ? ((page - 1) / 8) * 100 : 0;
  return (
    <GetStartedContainer>
      <StartedContainer>
        <StartedTop />

        <StartedProgress page={page} percentage={percentage} />

        {/* this should be dynamic */}
        {page === 1 ? (
          <StartedBuild
            setSelected={setSelected}
            selected={selected}
            setPage={setPage}
          />
        ) : (
          ""
        )}

        {page === 2 ? (
          <StartedForWho setSelected={setSelected} selected={selected} />
        ) : (
          ""
        )}
        {page === 3 ? (
          <StartedProjectName
            setSelected={setSelected}
            selected={selected}
            setPage={setPage}
          />
        ) : (
          ""
        )}

        {page === 4 ? (
          <StartedBusiness
            services={document}
            setSelected={setSelected}
            selected={selected}
          />
        ) : (
          ""
        )}

        {page === 5 && !selected.businessOthers ? (
          <StartedFeature
            services={document}
            setSelected={setSelected}
            selected={selected}
            showSelectedFeature={showSelectedFeature}
            setShowSelectedFeature={setShowSelectedFeature}
            setPage={setPage}
          />
        ) : (
          ""
        )}

        {page === 5 && selected.businessOthers ? (
          <StartedOthersBusiness
            setSelected={setSelected}
            selected={selected}
            setPage={setPage}
            page={page}
          />
        ) : (
          ""
        )}

        {page === 6 ? (
          <StartedFeaturePricingList
            services={document}
            setSelected={setSelected}
            selected={selected}
          />
        ) : (
          ""
        )}

        {page === 7 ? (
          <StartedPhoneVerification
            setPhoneNumber={setPhoneNumber}
            setPage={setPage}
            setSelected={setSelected}
            selected={selected}
            phoneNumber={phoneNumber}
          />
        ) : (
          ""
        )}

        {page === 8 ? (
          <StartedUserInfo
            phoneNumber={phoneNumber}
            services={document}
            setSelected={setSelected}
            selected={selected}
            setPage={setPage}
            page={page}
          />
        ) : (
          ""
        )}

        {page === 9 ? (
          <PreviewPage
            phoneNumber={phoneNumber}
            services={document}
            setSelected={setSelected}
            selected={selected}
          />
        ) : (
          ""
        )}

        {page <= 7 && (
          <StartedBottom
            setPage={setPage}
            page={page}
            selected={selected}
            phoneNumber={phoneNumber}
            showSelectedFeature={showSelectedFeature}
          />
        )}
        <GetStartedFooter />
      </StartedContainer>
    </GetStartedContainer>
  );
}
