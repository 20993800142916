import React from 'react'
import { Link } from 'react-router-dom'
import ProtectedRoute from '../../../ProtectedRoute/ProtectedRoute'
import { Button, Container } from '../../../styles/Common.styled'
import { UserDashboard } from '../../../styles/UserAdmin.styled'
import AdminSidebar from './AdminSidebar'

export default function CreateOrders() {
    return (
        <ProtectedRoute>
            <UserDashboard>
                <AdminSidebar />
                <Container>
                    <h2 style={{ textAlign: "center", fontSize: "3rem" }}>Create Orders</h2>
                    <div style={{ display: "flex", flexDirection: "column", gap: "2rem" , width: "800px" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                            <Link to="/super-admin/create-order" style={{ color: "currentcolor", textDecoration: "none" }}>
                                <div><Button>Create Order</Button></div>
                            </Link>
                            <Link to="/super-admin/created-order" style={{ color: "currentcolor", textDecoration: "none" }}>
                                <div><Button>Created Order</Button></div>
                            </Link>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                            <Link to="/super-admin/cash-in-flow" style={{ color: "currentcolor", textDecoration: "none" }}>
                                <div><Button>Cash In Flow</Button></div>
                            </Link>
                            <Link to="/super-admin/all-cash-in-flow" style={{ color: "currentcolor", textDecoration: "none" }}>
                                <div><Button>All Cash In Flow</Button></div>
                            </Link>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                            <Link to="/super-admin/cash-out-flow" style={{ color: "currentcolor", textDecoration: "none" }}>
                                <div><Button>Cash Out flow</Button></div>
                            </Link>
                            <Link to="/super-admin/all-cash-out-flow" style={{ color: "currentcolor", textDecoration: "none" }}>
                                <div><Button>Cash Out flow</Button></div>
                            </Link>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                            <Link to="/super-admin/r&d" style={{ color: "currentcolor", textDecoration: "none" }}>
                                <div><Button>R&D</Button></div>
                            </Link>
                            <Link to="/super-admin/all-r&d" style={{ color: "currentcolor", textDecoration: "none" }}>
                                <div><Button>R&D</Button></div>
                            </Link>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                            <Link to="/super-admin/project-revenue" style={{ color: "currentcolor", textDecoration: "none" }}>
                                <div><Button>Project Revenue</Button></div>
                            </Link>
                        </div>
                    </div>
                </Container>
            </UserDashboard >
        </ProtectedRoute>
    )
}
