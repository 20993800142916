import styled from "styled-components";

export const GridTwo = styled.div`
        display: Grid;
        width: ${({ width }) => width || ""};
        gap: ${({ gap }) => gap || "2rem"};
        grid-template-columns: repeat(2, 1fr);
        grid-template-columns:  ${({ column }) => column || "repeat(2, 1fr)"};
        align-items: ${({ align }) => align || "center"};

        @media (max-width: ${({ theme }) => theme.mobile}) {
            grid-template-columns: 1fr;
            text-align:center;
        }
        @media (max-width: ${({ theme }) => theme.smallMobile}) {
            gap: 1rem;
        }
    `

export const GridThree = styled.div`
    display: Grid;
    gap: ${({ gap }) => gap || "2rem"};
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    align-content: center;
    align-items: ${({ align }) => align};

    @media (max-width: ${({ theme }) => theme.mobile}) {
        grid-template-columns: 1fr;
    }
    @media (max-width: ${({ theme }) => theme.smallMobile}) {
        gap: 1rem;
    }
`

export const GridFour = styled.div`
    display: Grid;
    gap: ${({ gap }) => gap || "2rem"};
    grid-template-columns: repeat(4, 1fr);
    align-items: ${({ align }) => align};

    @media (max-width: ${({ theme }) => theme.mobile}) {
        grid-template-columns: 1fr 1fr;
    }
    @media (max-width: ${({ theme }) => theme.smallMobile}) {
        grid-template-columns: 1fr;
        gap: 1rem;
        text-align: center;
    }
`


export const Flex = styled.div`
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: ${({ justify }) => justify || "center"};
    & > div {
        flex: 1;
    }
    @media (max-width: ${({ theme }) => theme.mobile}) {
        flex-direction: column;
        text-align: center;
    }
`