import React from 'react'
import ProtectedRoute from '../../ProtectedRoute/ProtectedRoute'
import { UserDashboard } from '../../styles/UserAdmin.styled'
import AdminLogin from '../Login/AdminLogin'
import AdminContents from './Components/AdminContents'
import AdminServiceForm from './Components/AdminServiceForm'
import AdminSidebar from './Components/AdminSidebar'
import AllSelectedFeature from './Components/AllSelectedFeature'

export default function SuperAdmin() {
    return (
        <ProtectedRoute>
            <UserDashboard>
                <AdminSidebar />
                <AdminContents />
                {/* <AdminServiceForm />
            <AllSelectedFeature /> */}
            </UserDashboard>
        </ProtectedRoute>
    )
}
