import React from 'react'
import { PersonCard } from '../styles/Common.styled'
import facebook from "../assets/images/fb.png"
import linkedin from "../assets/images/linkedin1.png"
import twitter from "../assets/images/twitter.svg"
import mail from "../assets/images/email.png"

export default function Person({ img, name, position }) {
    return (
        <PersonCard>
            <div className='img_container'>
                <img src={img} alt={name} />
            </div>
            <div className='details_container'>

                <div className='detail'>
                    <h3>{name}</h3>
                    <p>{position}</p>
                </div>
                <div className='social'>
                    <a href="https://www.facebook.com/ongshak/" target="_blank">
                        <img src={facebook} alt="facebook" />
                    </a>
                    <a href="https://www.linkedin.com/company/ongshak/mycompany/" target="_blank">
                        <img src={linkedin} alt="linkedin" />
                    </a>
                    <a href="mailto:hello@ongshak.com" target="_blank">
                        <img src={mail} alt="twitter" />
                    </a>
                </div>
            </div>

            <div className='details_containers'>

                <div className='details'>
                    <h3>{name}</h3>
                    <p>{position}</p>
                </div>
                <div className='socials'>
                    <a href="https://www.facebook.com/ongshak/" target="_blank">
                        <img src={facebook} alt="facebook" />
                    </a>
                    <a href="https://www.linkedin.com/company/ongshak/mycompany/" target="_blank">
                        <img src={linkedin} alt="linkedin" />
                    </a>
                    <a href="mailto:hello@ongshak.com" target="_blank">
                        <img src={mail} alt="twitter" />
                    </a>
                </div>
            </div>
        </PersonCard>
    )
}
