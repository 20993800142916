import React, { useEffect, useState } from 'react'
import { useCollection } from '../../../hooks/useCollection'
import { useFirestore } from '../../../hooks/useFirestore'
import ProtectedRoute from '../../../ProtectedRoute/ProtectedRoute'
import { ServiceFormContainer } from '../../../styles/SuperAdmin.styled'
import { UserDashboard } from '../../../styles/UserAdmin.styled'
import AdminSidebar from './AdminSidebar'

export default function AdminCreateRandD() {
    let [error, setError] = useState("");
    let [selectedOrder, setSelectedOrder] = useState(null);
    const [rAndD, setRAndD] = useState({
        order_id: "",
        cost: "",
        amount: "",
    })

    const { updateDocument, response } = useFirestore("create_order");
    const { document } = useCollection("create_order");

    useEffect(() => {
        let filteredData = document?.filter(data => {
            return data?.order_id === rAndD.order_id
        });
        setSelectedOrder(filteredData[0])
    }, [rAndD?.order_id])

    const handleRandD = e => {
        e.preventDefault();
        const updatedObj = {
            ...selectedOrder,
            rAndD
        }

        if (rAndD?.order_id !== "" && rAndD?.cost !== "" && rAndD?.amount !== "") {
            updateDocument(selectedOrder?.id, updatedObj);
        } else {
            setError("Please fill all the required field!");
            return
        }

        setRAndD({
            order_id: "",
            project_name: "",
            cost: "",
            amount: "",
        })
    }
    return (
        <ProtectedRoute>
            <UserDashboard>
                <AdminSidebar />
                <ServiceFormContainer style={{ margin: "2rem auto" }}>
                    <h2 style={{ textAlign: "center", fontSize: "3rem" }}>R&D create</h2>

                    <form onSubmit={handleRandD}>
                        <label>
                            <p>Project Name:*</p>
                            <select onChange={e => setRAndD({ ...rAndD, order_id: e.target.value })}>
                                <option value="">Select an option</option>
                                {
                                    document?.map(project => (
                                        <option value={project?.order_id}>{project?.project_name}</option>
                                    ))
                                }
                            </select>
                        </label>
                        {/* <label>
                            <p>Project Name:*</p>
                            <input type="text" placeholder='Project Name' value={rAndD.project_name} onChange={e => setRAndD({ ...rAndD, project_name: e.target.value })} />
                        </label> */}
                        <label>
                            <p>Cost:*</p>
                            <select onChange={e => setRAndD({ ...rAndD, cost: e.target.value })}>
                                <option value="">Select an option</option>
                                <option value="Component">Component</option>
                                <option value="Transportation">Transportation</option>
                                <option value="Assembly">Assembly</option>
                            </select>
                        </label>

                        <label>
                            <p>Amount:*</p>
                            <input type="number" placeholder='Amount' value={rAndD.amount} onChange={e => setRAndD({ ...rAndD, amount: e.target.value })} />
                        </label>

                        <input type="submit" value="Submit" />
                    </form>
                    {
                        error && <p style={{ textAlign: "center", color: "red", fontSize: "2rem" }}>{error}</p>
                    }
                    {/* <Button >Submit</Button> */}
                </ServiceFormContainer >
            </UserDashboard >
        </ProtectedRoute>
    )
}
