import React from "react";
import { FactorySection } from "../../../styles/FactoryAutomation.Styled";
import { Container } from "../../../styles/Common.styled";
import { useState } from "react";

import emailjs from "@emailjs/browser";
import { useFirestore } from "../../../hooks/useFirestore";

export default function FactoryAutomationForm() {
  let [detail, setDetail] = useState({
    name: "",
    phone: "",
    email: "",
    companyName: "",
    idea: "",
  });

  let [success, setSuccess] = useState("");
  let [error, setError] = useState("");

  const { addDocument } = useFirestore("Factory_Automation");

  const handleSubmit = (e) => {
    e.preventDefault();
    const templateParams = {
      name: detail.name,
      user_email: detail.email,
      user_phone: detail.phone,
      companyName: detail.companyName,
      idea: detail.idea,
    };

    if (
      detail.name !== "" &&
      detail.phone !== "" &&
      detail.email !== "" &&
      detail.companyName !== "" &&
      detail.idea !== ""
    ) {
      addDocument(templateParams);
      emailjs
        .send(
          "service_mvl6und",
          "template_22avzsm",
          templateParams,
          "T6QISHKdsYsxMoeIM"
        )
        .then(
          (response) => {
            setError("");
            setSuccess("Successfully Submited");
            setDetail({
              name: "",
              phone: "",
              email: "",
              companyName: "",
              idea: "",
            });
          },
          (err) => {
            setError(err);
            setSuccess("");
          }
        );
    } else {
      setError("Please, fill all the required field.");
    }
  };
  return (
    <FactorySection>
      <Container>
        <h1 className="">Please fill this form for Automation service</h1>
        <div className="contact_form">
          <hr
            style={{
              display: "block",
              width: "130px",
              margin: "0 auto 4rem",
              height: "3px",
              backgroundColor: "rgba(253, 161, 9, 1)",
              border: 0,
            }}
          />
          <form onSubmit={handleSubmit}>
            <div className="flex">
              <input
                type="text"
                value={detail.name}
                onChange={(e) => setDetail({ ...detail, name: e.target.value })}
                placeholder="Your name"
              />
              <input
                type="tel"
                value={detail.phone}
                onChange={(e) =>
                  setDetail({ ...detail, phone: e.target.value })
                }
                placeholder="Your phone number"
              />
            </div>
            <div className="flex">
              <input
                type="email"
                value={detail.email}
                onChange={(e) =>
                  setDetail({ ...detail, email: e.target.value })
                }
                placeholder="Your email"
              />
              <input
                type="text"
                value={detail.companyName}
                onChange={(e) =>
                  setDetail({ ...detail, companyName: e.target.value })
                }
                placeholder="Your company name"
              />
            </div>
            <input
              type="text"
              value={detail.idea}
              onChange={(e) => setDetail({ ...detail, idea: e.target.value })}
              placeholder="Please send your message"
            />
            <div className="submit_container">
              <input type="submit" value="Submit" className="submit" />
            </div>
          </form>
          {success && (
            <p
              style={{
                textAlign: "center",
                color: "green",
                fontSize: "1.8rem",
              }}
            >
              {success}
            </p>
          )}
          {error && (
            <p
              style={{ textAlign: "center", color: "red", fontSize: "1.8rem" }}
            >
              {error}
            </p>
          )}
        </div>
      </Container>
    </FactorySection>
  );
}
