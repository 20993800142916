import React, { useState } from 'react'
import { FaArrowAltCircleRight } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom'
import { useCollection } from '../../../hooks/useCollection';
import { useFirestore } from '../../../hooks/useFirestore';
import { Button, Container } from '../../../styles/Common.styled';
import { DetailsCard, Form, SelectedFeature } from '../../../styles/GetStarted.styled';

export default function SelectedFeatureDetails() {
    const { id } = useParams();
    const [selectedType, setSelectedType] = useState("");
    const [advancedPayment, setAdvancedPayment] = useState(0);
    const [orderDate, setOrderDate] = useState("");
    let [success, setSuccess] = useState("");

    let { document } = useCollection("Selected Feature");
    const { addDocument } = useFirestore("customers");
    const { addDocument: addOther } = useFirestore("notCustomer");
    const { updateDocument } = useFirestore("Selected Feature");

    const navigate = useNavigate("");

    let selectedFeature = document?.filter(feature => feature?.id === id);

    const handleSubmit = (e) => {
        e.preventDefault();
        let obj = selectedFeature[0];

        const updatedObj = {
            ...obj,
            type: selectedType
        }
        const customerObj = {
            ...obj,
            workOrderDate: orderDate,
            productDetails: {
                ...obj?.productDetails,
                advancedPayment
            },
            isUiDone: {
                developed: false,
                review: false,
                approved: false,
                uiLink: "",
            },
            isDevelopmentDone: false,
            isTestingDone: false,
            isDeliveryDone: false,
            developmentLink: "",
            hostingLink: ""
        }

        if (selectedType === "customer") {
            updateDocument(obj?.id, updatedObj)
            addDocument(customerObj);
        }
        if (selectedType !== "lead" && selectedType !== "customer") {
            updateDocument(obj?.id, updatedObj)
            addOther(customerObj);
        }

        setTimeout(() => {
            setSuccess("customer dashboard created with your provided details")
        }, 3000)

    }

    return (
        <div>
            <div style={{ maxWidth: "70%", margin: "0 auto" }}>
                <DetailsCard align="stretch">
                    <div>
                        <p className="bold">Serial Number:</p>
                        <p>{selectedFeature[0]?.serialNumber}</p>
                    </div>
                    <div>
                        <p className="bold">Customer Name:</p>
                        <p>{selectedFeature[0]?.userDetails?.name}</p>
                    </div>
                    <div>
                        <p className="bold">Customer Email:</p>
                        <p>{selectedFeature[0]?.userDetails?.email}</p>
                    </div>
                    <div>
                        <p className="bold">Customer phoneNumber:</p>
                        <p>{selectedFeature[0]?.userDetails?.phoneNumber}</p>
                    </div>
                    <div>
                        <p className="bold">Project Name:</p>
                        <p>{selectedFeature[0]?.projectName}</p>
                    </div>
                    <div>
                        <p className="bold">Project Purpose:</p>
                        <p>{selectedFeature[0]?.projectPurpose}</p>
                    </div>
                    <div>
                        <p className="bold">Project Types:</p>
                        <p>{selectedFeature[0]?.projectTypes?.map(type => type)}</p>
                    </div>
                    <div>
                        <p className="bold">Selected Business:</p>
                        <p>{selectedFeature[0]?.business}</p>
                    </div>
                    <div>
                        <p className="bold">Total Price:</p>
                        <p>{selectedFeature[0]?.productDetails?.price} BDT</p>
                    </div>
                    <div>
                        <p className="bold">Feature Selected:</p>
                        {/* <div style={{ width: "50%", display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                            {
                                selectedFeature[0]?.features?.map(feature => (
                                    <div style={{ flexBasis: "50%", dispaly: "flex", gap: "1rem", marginBottom: "1rem" }} >
                                        <FaArrowAltCircleRight />
                                        <p>{feature?.featureName}</p>
                                    </div>
                                ))
                            }
                        </div> */}
                        {/* <p>{selectedFeature[0]?.features?.map(feature => feature?.featureName)}</p> */}
                    </div>
                    <div style={{ width: "100%", gap: "1rem", display: "flex", flexWrap: "wrap" }}>
                        {
                            selectedFeature[0]?.features?.map((feature, index) => (
                                <div key={index} style={{ flexBasis: "32%", dispaly: "flex", gap: "1rem", marginBottom: "1rem", backgroundColor: "#ddd", padding: "1rem" }} >
                                    <FaArrowAltCircleRight />
                                    <p>{feature?.featureName}</p>
                                    

                                </div>
                            ))
                        }
                    </div>


                </DetailsCard>
            </div>

            <Form onSubmit={handleSubmit}>
                <label>
                    <p>customer type</p>
                    <select value={selectedType} onChange={e => setSelectedType(e.target.value)}>
                        <option value="lead" >lead</option>
                        <option value="customer" >customer</option>
                        <option value="processing" >Processing</option>
                        <option value="lead lost" >Lead Lost</option>
                        <option value="noise" >Noise</option>
                    </select>
                </label>
                <label>
                    <p>advanced payment</p>
                    <input value={advancedPayment} type="number" onChange={e => setAdvancedPayment(e.target.value)} />
                </label>
                <label>
                    <p>order date</p>
                    <input value={orderDate} type="date" onChange={e => setOrderDate(e.target.value)} />
                </label>

                <input type="submit" value="submit" />
                {
                    success !== "" ? <p style={{ fontSize: "2rem", color: "#fff" }}>{success}</p> : ""
                }
            </Form>
        </div>
    )
}
