import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useLogin } from '../../hooks/useLogin';
import { Form } from '../../styles/GetStarted.styled'
import { LoginSection } from '../../styles/Login.styled';

export default function Login() {
    let [email, setEmail] = useState("");
    let [password, setPassword] = useState("");
    const [customError, setCustomError] = useState("");

    const { error, Login, isPending } = useLogin()
    const navigate = useNavigate("");

    const handleSubmit = e => {
        e.preventDefault();
        if (email === "" || password === "") {
            setCustomError("Input field have to be fill up");
            return
        }
        Login(email, password)
    }
    return (
        <LoginSection >
            <h2 style={{ marginBottom: "3rem", fontSize: "3rem" }}>Login</h2>
            <Form onSubmit={handleSubmit} width="50%">
                <label>
                    <p>your email</p>
                    <input type="text" value={email} onChange={e => setEmail(e.target.value)} placeholder="your email" />
                </label>
                <label>
                    <p>your password</p>
                    <input type="password" value={password} onChange={e => setPassword(e.target.value)} placeholder="your password" />
                </label>
                {/* <input type="submit" value={isPending ? "processing" : "login"} /> */}
                {!isPending && <input type="submit" value="login" />}
                {isPending && <input value="processing" />}
            </Form>
            {
                error ? <p>{error}</p> : ""
            }
            {
                customError ? <p>{customError}</p> : ""
            }
        </LoginSection>
    )
}
