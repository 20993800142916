import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useCollection } from "../../../hooks/useCollection";
import { useFirestore } from "../../../hooks/useFirestore";
import Facebook from "../../../Shared/Facebook/Facebook";
import Footer from "../../../Shared/Footer";
import Linkedin from "../../../Shared/Linkedin/Linkedin";
import Navbar from "../../../Shared/Navbar";
import Twitter from "../../../Shared/Twitter/Twitter";
import {
  Blog,
  BlogInput,
  BlogInputTextarea,
  BlogInputWrapper,
  BlogReview,
} from "../../../styles/Blog.styled";
import { Button, Container } from "../../../styles/Common.styled";
import { Flex, GridThree, GridTwo } from "../../../styles/Grid.styled";
import { FaHeart } from "react-icons/fa";
import { Dna } from "react-loader-spinner";

export default function BlogLearnMore() {
  const [ipAddress, setIpAddress] = useState("");
  const [selected, setSelected] = useState({});
  const [blogReview, setBlogReview] = useState({
    name: "",
    email: "",
    comment: "",
    website: "",
    createdAt: Date.now(),
  });
  const [error, setError] = useState("");

  const { blogtitle } = useParams();
  const { document: blogs } = useCollection("Blogs");
  const { document: RecentBlogs } = useCollection("Blogs", 3, [
    "createdAt",
    "desc",
  ]);
  const { updateDocument, response } = useFirestore("Blogs");

  console.log("blogtitle", blogtitle);

  useEffect(() => {
    let filteredData = blogs?.filter((blog) => {
      let paramsTitle = blog?.title?.split(" ").join("-");
      if (paramsTitle[paramsTitle?.length - 1] === "?") {
        paramsTitle = paramsTitle.replaceAll("?", "");
      }
      return paramsTitle == blogtitle;
    });
    setSelected(filteredData[0]);
  }, [blogs, blogtitle]);

  const handleUpdate = (e) => {
    let updatedBlog;
    if (!selected?.reaction?.includes(ipAddress)) {
      updatedBlog = {
        ...selected,
        reaction: [...selected.reaction, ipAddress],
      };
      updateDocument(selected?.id, updatedBlog);
    } else {
      let findIp = selected?.reaction?.findIndex((ip) => ip == ipAddress);
      let updatedReaction = selected?.reaction?.splice(findIp, 1);
      updatedBlog = {
        ...selected,
        reaction: selected?.reaction,
      };

      updateDocument(selected?.id, updatedBlog);
    }
  };

  const handleCommentSubmit = (e) => {
    setError("");
    if (
      blogReview.comment !== "" &&
      blogReview.name !== "" &&
      blogReview.email !== ""
    ) {
      const updatedBlog = {
        ...selected,
        reviews: [...selected.reviews, blogReview],
      };
      updateDocument(selected?.id, updatedBlog);

      setTimeout(() => {
        setBlogReview({
          name: "",
          email: "",
          comment: "",
          website: "",
          createdAt: Date.now(),
        });
      }, 2000);
    } else {
      setError("Please fill all required fields");
    }
  };

  useEffect(() => {
    fetch("https://api.ipify.org/?format=json")
      .then((response) => response.json())
      .then((data) => {
        const userIpAddress = data.ip;
        setIpAddress(userIpAddress); // outputs the user's IP address
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <>
      <Navbar />
      {selected?.id ? (
        <>
          <img
            style={{
              marginBottom: "3rem",
            }}
            src={selected?.blogImage}
            alt={selected?.title}
          />
          <Container style={{ overflow: "hidden" }}>
            <div className="blog_content">
              <div>
                <h2 style={{ fontSize: "2.6rem", marginBottom: "2rem" }}>
                  {selected?.title}
                </h2>
                <div style={{ lineHeight: "1.6" }}>
                  <div
                    dangerouslySetInnerHTML={{ __html: selected?.content }}
                  />
                </div>
                {selected?.id ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        gap: "2rem",
                        marginTop: "4rem",
                      }}
                    >
                      <Facebook
                        url={`https://ongshak.com/${blogtitle}`}
                        qoute={blogtitle}
                      />
                      <Linkedin
                        url={`https://ongshak.com/${blogtitle}`}
                        qoute={blogtitle}
                      />
                      <Twitter
                        url={`https://ongshak.com/${blogtitle}`}
                        qoute={blogtitle}
                      />
                    </div>

                    <div
                      style={{
                        marginTop: "4rem",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <h2>
                        <FaHeart
                          onClick={handleUpdate}
                          style={{
                            fill: `${
                              selected?.reaction?.includes(ipAddress)
                                ? "red"
                                : "#c3c3c3"
                            }`,
                            cursor: "pointer",
                          }}
                        />{" "}
                        {selected?.reaction?.length}{" "}
                        {selected?.reaction?.length > 1 ? "Likes" : "Like"}
                      </h2>
                    </div>
                  </>
                ) : null}

                {selected?.reviews?.length > 0 && (
                  <div style={{ marginTop: "3rem" }}>
                    <h2 style={{ marginBottom: "2rem" }}>All Comments</h2>
                    <GridTwo align="start">
                      {selected?.reviews?.map((review) => (
                        <BlogReview>
                          <p>{review.comment}</p>
                          <p className="name">{review.name}</p>
                        </BlogReview>
                      ))}
                    </GridTwo>
                  </div>
                )}

                {selected?.id ? (
                  <div style={{ margin: "2rem 0" }}>
                    <h1 className="heading">Leave a comment</h1>
                    <div style={{ fontSize: "1.6rem", marginTop: "1rem" }}>
                      <p>Your email address will not be published</p>
                      <p>Required fields are marked *</p>
                    </div>
                    <BlogInputWrapper>
                      {/* <p>Blog Title</p> */}
                      <BlogInputTextarea
                        placeholder="Type here*"
                        value={blogReview.comment}
                        onChange={(e) =>
                          setBlogReview({
                            ...blogReview,
                            comment: e.target.value,
                          })
                        }
                      />
                    </BlogInputWrapper>
                    <Flex>
                      <BlogInputWrapper style={{ width: "100%" }}>
                        {/* <p>Alt text</p> */}
                        <BlogInput
                          border="1px solid #0933A6"
                          placeholder="Name*"
                          value={blogReview.name}
                          onChange={(e) =>
                            setBlogReview({
                              ...blogReview,
                              name: e.target.value,
                            })
                          }
                        />
                      </BlogInputWrapper>
                      <BlogInputWrapper style={{ width: "100%" }}>
                        <BlogInput
                          border="1px solid #0933A6"
                          type="text"
                          placeholder="Email*"
                          value={blogReview.email}
                          onChange={(e) =>
                            setBlogReview({
                              ...blogReview,
                              email: e.target.value,
                            })
                          }
                        />
                      </BlogInputWrapper>
                      <BlogInputWrapper style={{ width: "100%" }}>
                        <BlogInput
                          border="1px solid #0933A6"
                          type="text"
                          placeholder="Website"
                          value={blogReview.website}
                          onChange={(e) =>
                            setBlogReview({
                              ...blogReview,
                              website: e.target.value,
                            })
                          }
                        />
                      </BlogInputWrapper>
                    </Flex>
                    {error !== "" ? (
                      <p style={{ fontSize: "1.6rem", color: "red" }}>
                        {error}
                      </p>
                    ) : (
                      ""
                    )}
                    <div>
                      <button
                        onClick={handleCommentSubmit}
                        style={{
                          border: "none",
                          padding: "10px 15px",
                          backgroundColor: "#4F82CD",
                          color: "#fff",
                          cursor: "pointer",
                        }}
                      >
                        Post Comment
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>
              <div>
                <img src={selected?.socialImage} alt={selected?.title} />
              </div>
            </div>
          </Container>
        </>
      ) : (
        <div style={{ textAlign: "center" }}>
          <Dna
            visible={true}
            height="120"
            width="120"
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
          />
        </div>
      )}

      <Container>
        <h2
          className="heading"
          style={{ textAlign: "center", fontSize: "2.6rem", padding: "1rem" }}
        >
          Recent Case Studies
        </h2>
        <hr
          style={{
            display: "block",
            width: "130px",
            margin: "0 auto 4rem",
            height: "3px",
            backgroundColor: "rgba(253, 161, 9, 1)",
            border: 0,
          }}
        />
        <GridThree gap="7rem">
          {RecentBlogs?.map((blog) => {
            let blogParams = blog?.title?.split(" ").join("-");
            return (
              <NavLink
                to={`/case-studies/${blogParams}`}
                style={{ textDecoration: "none", cursor: "pointer" }}
              >
                <Blog>
                  <img src={blog?.featureImage} alt={blog?.title} />
                  {/* <div className='content'>
                                        <h1>{blog?.title}</h1>
                                        <p>Read Article</p>
                                    </div> */}
                  <div className="blog_title">
                    <h1 className="category">
                      {blog?.tags?.map((tag, index) =>
                        index !== blog?.tags?.length - 1
                          ? ` ${tag},`
                          : ` ${tag}`
                      )}
                    </h1>
                    <a href={blog?.aboutCaseStudy?.url} target="_blank">
                      <h2>{blog?.aboutCaseStudy?.name}</h2>
                    </a>
                  </div>
                </Blog>
              </NavLink>
            );
          })}
        </GridThree>
      </Container>
      <Footer />
    </>
  );
}
