import styled from "styled-components";

export const BlogSection = styled.footer`
  padding: 2rem 0;
  max-width: 900px;
  margin: 0 auto;

  h2 {
    font-size: 2.4rem;
    margin-bottom: 2rem;
  }
  button {
    padding: 1rem 2rem;
    border: none;
    background-color: #0933a6;
    color: #fff;
    border-radius: 0.4rem;
    cursor: pointer;
    opacity: 0.9;
    transition: all 0.3s;
    :hover {
      opacity: 1;
    }
  }
`;

export const BlogInputWrapper = styled.div`
  margin-bottom: 1rem;

  p {
    font-size: 1.8rem;
  }
`;
export const BlogImageWrapper = styled.div`
  max-width: 500px;
  height: auto;
  margin: 1rem 0;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  overflow: hidden;
  img {
  }
`;

export const BlogInput = styled.input`
  width: 100%;
  border: none;
  border-bottom: 1px solid #0933a6;
  border: ${({ border }) => border || ""};
  border-radius: 4px;
  color: #0933a6;
  padding: 1rem;
  margin: 1rem 0 2rem;
  font-size: 1.6rem;
  ::placeholder {
    color: rgba(9, 51, 166, 0.5);
  }
  :focus {
    outline: none;
  }
`;

export const BlogInputTextarea = styled.textarea`
  width: 100%;
  border: none;
  border: 1px solid #0933a6;
  color: #0933a6;
  padding: 0.5rem;
  margin: 1rem 0 2rem;
  font-size: 1.6rem;
  resize: none;
  height: 200px;
  border-radius: 4px;
  ::placeholder {
    color: rgba(9, 51, 166, 0.5);
  }
  :focus {
    outline: none;
  }
`;

export const BlogInputFile = styled.input`
  width: 100%;
  border: none;
  border: 1px solid #0933a6;
  color: #0933a6;
  padding: 0.5rem;
  margin: 1rem 0 2rem;
  font-size: 1.6rem;
  ::placeholder {
    color: rgba(9, 51, 166, 0.5);
  }
  :focus {
    outline: none;
  }
`;

export const Blog = styled.div`
  border-radius: 8px;
  min-height: 250px;
  /* box-shadow: 2px 2px 8px rgba(0,0,0,.2); */
  position: relative;

  :hover {
  }

  img {
    /* position: absolute; */
    object-fit: contain;
    /* top: 0;
        right: 0;
        left: 0;
        bottom: 0 */
  }

  .blog_title {
    text-align: center;
    .category {
      font-size: 1.8rem;
      padding: 1.5rem;
      color: #656565;
      text-transform: uppercase;
    }
    h2 {
      font-size: 2.2rem;
      padding: 1.5rem;
      color: #4f82cd;
    }

    a {
      text-decoration: none;
      z-index: 10;
    }
    p {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 0.8rem;
      border-top-right-radius: 0rem;
      border-top-left-radius: 0rem;
      color: #fff;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.5s ease-in-out;

      font-size: 2.8rem;
      opacity: 0;
    }
  }

  :hover {
    .blog_title {
      p {
        opacity: 1;
      }
    }
  }

  :hover {
    cursor: pointer;
    .content h1 {
      transform: translateY(-80px);
    }
    .content p {
      transform: translateY(0px);
    }
  }

  .content {
    /* position: absolute; */
    left: 00px;
    bottom: 0px;
    width: 100%;
    min-height: 100px;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 2rem;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    font-size: 1.8rem;
    overflow: hidden;

    h1 {
      transition: all 0.3s ease-in;
      transform: translateY(0px);
      font-size: 1.8rem;
    }
    p {
      transform: translateY(80px);
      transition: all 0.3s ease-in;
    }
  }
  /* 
    h1 {
        font-size: 1.8rem;
        text-transform: uppercase;
        margin-top: 2rem;
        color: #fff;
        color: #3f3f3f;
        z-index: 100;
    } */

  button {
    border: none;
    padding: 10px 15px;
    background-color: #4f82cd;
    color: #fff;
    margin-top: 2rem;
    cursor: pointer;
  }
`;

export const BlogReview = styled.div`
  background-color: #fff;
  border-radius: 0.4rem;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4);

  padding: 3rem;

  p {
    color: #444444;
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
  }
  .name {
    font-size: 1.6rem;
    color: #0933a6;
    font-style: italic;
    font-weight: 700;
  }
`;
