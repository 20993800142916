import React, { useRef, useState } from 'react'
import { Container, Heading } from '../../../../styles/Common.styled'
import { GridThree } from '../../../../styles/Grid.styled'
import { TestimonialCard, TestimonialSection } from '../../../../styles/Home.styled'
import mahin from "../../../../assets/images/mahin.jpg"
import nahiyan from "../../../../assets/images/nahiyan.jpg"
import ashik from "../../../../assets/images/ashik.png"

import { Swiper, SwiperSlide, SwiperProps } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";


// import required modules
import "swiper/css/navigation";
import SwiperCore, { Autoplay, Navigation } from 'swiper';


const reviews = [
    {
        id: 1,
        clientName: "Mahin Abrar Rahman",
        clientReview: "I had a wonderful time with Ongshak. They were collaborative, understanding and professional. The website that they made for me was fantastic and served my purpose perfectly.",
        clientDesignation: "Director, Psycure Limited",
        clientImage: mahin
    },
    {
        id: 2,
        clientName: "Syed Al-Nahiyan",
        clientReview: "Truly a smart tech solution for those who want to grow together with an expert and efficient team. Good luck.",
        clientDesignation: "CEO, The Physics Guy",
        clientImage: nahiyan
    },
    {
        id: 3,
        clientName: "Ashik Muntakim",
        clientReview: "It has been a pleasure working with Ongshak for several of our projects. Ongshak has been a constant support for all of our Tech requirements so far with affordable solutions and great input in our tech products. And their all-time troubleshooting is a blessing for their clients. They have been a very capable alternative solution for an in-house tech team which has drastically reduced our expenditures as well",
        clientReview2: "I would undoubtedly recommend Ongshak for any sort of digital and technological support. Hope to collaborate further in future.",
        clientDesignation: "Chief of Staff, Healthport Bangladesh",
        clientImage: ashik
    },
]

const SwiperConfig = {
    speed: 3000,
    autoplay: {
        delay: 5,
        disableOnInteraction: false,
        pauseOnMouseEnter: true
    },
    loop: true,
    slidesPerView: "auto",
    watchSlidesProgress: true,
    spaceBetween: 27,
    grabCursor: true
};

SwiperCore.use([Autoplay, Navigation]);

export default function Testimonial() {

    const swiperRef = useRef(SwiperCore);
    const [hover, onHover] = useState(false);

    const onInit = (SwiperCore) => {
        swiperRef.current = SwiperCore;
    };

    const handleMouseEnter = () => {
        onHover(true);
        if (swiperRef.current) swiperRef.current.autoplay.stop();
    };
    const handleMouseLeave = () => {
        onHover(false);
        if (swiperRef.current) swiperRef.current.autoplay.start();
    };



    return (
        <TestimonialSection>
            <Container style={{ overflow: "hidden" }}>

                <h2 className='heading'>Testimonial</h2>
                <hr style={{ display: 'block', width: '10%', margin: "0 auto 6rem", height: '3px', backgroundColor: 'rgba(253, 161, 9, 1)', border: 0 }} />
                {/* <Heading mb="12rem">
                    <p>the business cerrent account that is your accounting software. a simple way to run your bussiness.</p>
                </Heading> */}



                <Swiper
                    {...SwiperConfig}
                    onInit={onInit}
                    style={{ padding: "0 1rem" }}
                    spaceBetween={30}
                    grabCursor={false}
                    centeredSlides={true}
                    slidesPerView={"auto"}
                    hashNavigation={{
                        watchState: true,
                    }}
                    autoplay={{
                        delay: 2000,
                        disableOnInteraction: true,
                    }}
                    breakpoints={{
                        1024: {
                            slidesPerView: 1,
                            spaceBetween: 20,

                        }
                    }}
                    // navigation={true}
                    modules={[Autoplay, Navigation]}
                >

                    {
                        reviews.map(review => (
                            <SwiperSlide className='slide'>
                                <TestimonialCard onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}>
                                    <img src={review?.clientImage} alt="ongshak review" />
                                    <div className='content'>
                                        <h2>{review.clientName}</h2>
                                        <p className="designation">{review.clientDesignation}</p>
                                        <hr style={{ display: 'block', width: '30%', margin: "0 auto 2rem", height: '3px', backgroundColor: '#ddd', border: 0 }} />
                                        <p>{review.clientReview}</p>
                                    </div>
                                </TestimonialCard>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>


                {/* <GridThree>
                <TestimonialCard>
                        <img src={mahin} alt="ongshak review" />
                        <div className='content'>
                            <h2>Mahin Abrar Rahman</h2>
                            <p className="designation">Director, Psycure Limited</p>
                            <hr style={{ display: 'block', width: '30%', margin: "0 auto 2rem", height: '3px', backgroundColor: '#ddd', border: 0 }} />
                            <p>I had a wonderful time with Ongshak. They were collaborative, understanding and professional. The website that they made for me was fantastic and served my purpose perfectly.</p>
                        </div>
                    </TestimonialCard>
                    <TestimonialCard>
                        <img src={nahiyan} alt="ongshak review" />
                        <div className='content'>
                            <h2>Syed Al-Nahiyan</h2>
                            <p className="designation">CEO, The Physics Guy</p>
                            <hr style={{ display: 'block', width: '30%', margin: "0 auto 2rem", height: '3px', backgroundColor: '#ddd', border: 0 }} />
                            <p>Truly a smart tech solution for those who want to grow together with an expert and efficient team. Good luck.</p>
                        </div>
                    </TestimonialCard>
                    <TestimonialCard>
                        <img src={ashik} alt="ongshak review" />
                        <div className='content'>
                            <h2>Ashik Muntakim</h2>
                            <p className="designation">Chief of Staff, Healthport Bangladesh</p>
                            <hr style={{ display: 'block', width: '30%', margin: "0 auto 2rem", height: '3px', backgroundColor: '#ddd', border: 0 }} />
                            <p>It has been a pleasure working with Ongshak for several of our projects. Ongshak has been a constant support for all of our Tech requirements so far with affordable solutions and great input in our tech products. And their all-time troubleshooting is a blessing for their clients. They have been a very capable alternative solution for an in-house tech team which has drastically reduced our expenditures as well.</p>
                            <p>I would undoubtedly recommend Ongshak for any sort of digital and technological support. Hope to collaborate further in future.</p>
                        </div>
                    </TestimonialCard>
                </GridThree> */}
            </Container>
        </TestimonialSection>
    )
}
