import React, { useEffect } from 'react'
import { useState } from 'react'
import Banner from '../../Shared/Banner'
import Footer from '../../Shared/Footer'
import Navbar from '../../Shared/Navbar'
import ContactForm from './Components/ContactForm'

export default function ContactUs() {
  const [height, setHeight] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, [])

  const listenToScroll = () => {
    const winScroll = document.body.scrollTop ||
      document.documentElement.scrollTop;
    setHeight(winScroll);
  };

  return (
    <div>
        <Navbar height={height} />
        <Banner>
            <h2>Contact Us</h2>
        </Banner>
        <ContactForm />
        <Footer />
    </div>
  )
}
